import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePlayerState } from "../context/PlayerStateContext";
import SetupModal from "./Player/SetupModal";
import ConfirmModal from "../components/additional/ConfirmModal";
import styles from "./HomePage.module.css";
import GameWrapper from "./GameWrapper";

const HomePage = () => {
  const navigate = useNavigate();
  const { playerState, setPlayerState, resetPlayerState } = usePlayerState();
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    const { playerName, gender } = playerState;
    if (!playerName || !gender) {
      setShowModal(true);
    }
  }, [playerState]);

  const handleButtonClick = (path) => {
    if (playerState.playerName && playerState.gender) {
      navigate(path);
    } else {
      setShowModal(true);
    }
  };

  const handleModalSubmit = (playerName, characterType) => {
    setPlayerState((prevState) => ({
      ...prevState,
      playerName,
      gender: characterType,
    }));
    setShowModal(false);
  };

  const handleResetClick = () => {
    setShowConfirmModal(true);
  };

  const handleResetConfirm = () => {
    resetPlayerState();
    setShowConfirmModal(false);
    setShowModal(true);
  };

  return (
    <GameWrapper backgroundImage="Home.webp">
      {showModal && <SetupModal onSubmit={handleModalSubmit} />}
      <button
        onClick={() => handleButtonClick("/cottage-spells")}
        className={`${styles.invisibleButton} ${styles.button1}`}
        disabled={!playerState.playerName || !playerState.gender}
      >
        <span className={styles.hoverText}>Cottage</span>
      </button>
      <button
        onClick={() => handleButtonClick("/map")}
        className={`${styles.invisibleButton} ${styles.button2}`}
        disabled={!playerState.playerName || !playerState.gender}
      >
        <span className={styles.hoverText}>Map</span>
      </button>
      <button
        onClick={() => handleButtonClick("/player")}
        className={`${styles.invisibleButton} ${styles.button3}`}
        disabled={!playerState.playerName || !playerState.gender}
      >
        <span className={styles.hoverText}>Player</span>
      </button>
      <button
        onClick={() => handleButtonClick("/wishing-well")}
        className={`${styles.invisibleButton} ${styles.button4}`}
        disabled={!playerState.playerName || !playerState.gender}
      >
        <span className={styles.hoverText}>Wishing Well</span>
      </button>

      <button
        onClick={() => handleButtonClick("/puzzle-hut")}
        className={`${styles.invisibleButton} ${styles.button5}`}
        disabled={!playerState.playerName || !playerState.gender}
      >
        <span className={styles.hoverText}>Puzzle Hut</span>
      </button>
      <button
        onClick={handleResetClick}
        className={styles.resetButton}
      >
        Reset
      </button>
      <ConfirmModal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleResetConfirm}
        message="Are you sure you want to reset your progress? This cannot be undone."
      />
    </GameWrapper>
  );
};

export default HomePage;