import React, { useState, useEffect, useCallback } from 'react';
import styles from './BubblePopLogic.module.css';
import { usePlayerState, generateRandomGold, generateRandomItem } from '../../context/PlayerStateContext';
import { mapItems } from '../../constants/itemConstants';
import PrizeModal from '../additional/PrizeModal';

const colors = ['red', 'blue', 'green', 'yellow', 'purple', 'orange'];
const numbers = Array.from({ length: 20 }, (_, i) => i + 1);
const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
const vowels = 'AEIOU'.split('');

const BubblePopLogic = () => {
  const { playerState, setPlayerState, addItem, incrementPuzzleCompletionCount } = usePlayerState();
  const [bubbles, setBubbles] = useState([]);
  const [rule, setRule] = useState('');
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60);
  const [gameActive, setGameActive] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showPrizeModal, setShowPrizeModal] = useState(false);
  const [prize, setPrize] = useState({ gold: 0, item: null, score: 0 });
  const [isWin, setIsWin] = useState(false);
  const [earnedReward, setEarnedReward] = useState(null);

  const requiredScore = 20; // Set the required score to win

  const generateNewRound = useCallback(() => {
    const ruleTypes = [
      'Click odd numbers',
      'Click even numbers',
      `Click all ${colors[Math.floor(Math.random() * colors.length)]} bubbles`,
      'Click numbers greater than 10',
      'Click numbers less than or equal to 10',
      'Click letters that are vowels',
      'Click letters between A and L',
      'Click letters between M and Z'
    ];
    const newRule = ruleTypes[Math.floor(Math.random() * ruleTypes.length)];
    setRule(newRule);

    const newBubbles = Array.from({ length: 20 }, (_, index) => {
      const type = Math.random() < 0.4 ? 'number' : Math.random() < 0.7 ? 'letter' : 'color';
      return {
        id: index,
        type,
        value: type === 'number' ? numbers[Math.floor(Math.random() * numbers.length)] :
               type === 'letter' ? letters[Math.floor(Math.random() * letters.length)] :
               colors[Math.floor(Math.random() * colors.length)],
        status: null // null: unclicked, 'correct': clicked and correct, 'incorrect': clicked and incorrect
      };
    });
    setBubbles(newBubbles);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setVisible(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const earnReward = useCallback(() => {
    const gold = generateRandomGold();
    const mapId = 'noble_lands';
    const mapData = mapItems.find(map => map.id === mapId);
    const item = generateRandomItem(mapData.items);
    return { gold, item };
  }, []);

  const endGame = useCallback(() => {
    setGameActive(false);
    const finalScore = score;
    const isWinner = finalScore >= requiredScore;

    const earnedPrize = earnReward();
    setEarnedReward(earnedPrize);
    setPrize({
      gold: isWinner ? earnedPrize.gold : 0,
      item: isWinner ? earnedPrize.item : null,
      score: finalScore,
      goldImagePath: "/gold.png",
      experience: isWinner ? 0.02 : 0
    });

    setShowPrizeModal(true);
    setIsWin(isWinner);

    if (isWinner) {
      setPlayerState(prevState => ({
        ...prevState,
        gold: prevState.gold + earnedPrize.gold,
        level: prevState.level + 0.02
      }));

      incrementPuzzleCompletionCount('noble_lands', 9);
      
      if (earnedPrize.item) {
        addItem(earnedPrize.item);
      }
    }
  }, [score, earnReward, incrementPuzzleCompletionCount, addItem, requiredScore, setPlayerState]);

  useEffect(() => {
    if (timeLeft > 0 && gameActive) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0 && gameActive) {
      endGame();
    }
  }, [timeLeft, gameActive, endGame]);

  const isCorrectBubble = useCallback((bubble) => {
    if (bubble.status === 'correct') return false; // Already clicked correct bubble
    if (rule === 'Click odd numbers' && bubble.type === 'number' && bubble.value % 2 !== 0) return true;
    if (rule === 'Click even numbers' && bubble.type === 'number' && bubble.value % 2 === 0) return true;
    if (rule.includes('Click all') && bubble.type === 'color' && bubble.value === rule.split(' ')[2]) return true;
    if (rule === 'Click numbers greater than 10' && bubble.type === 'number' && bubble.value > 10) return true;
    if (rule === 'Click numbers less than or equal to 10' && bubble.type === 'number' && bubble.value <= 10) return true;
    if (rule === 'Click letters that are vowels' && bubble.type === 'letter' && vowels.includes(bubble.value)) return true;
    if (rule === 'Click letters between A and L' && bubble.type === 'letter' && bubble.value >= 'A' && bubble.value <= 'L') return true;
    if (rule === 'Click letters between M and Z' && bubble.type === 'letter' && bubble.value >= 'M' && bubble.value <= 'Z') return true;
    return false;
  }, [rule]);

  const handleBubbleClick = (bubble) => {
    if (!gameActive || bubble.status !== null) return;

    const isCorrect = isCorrectBubble(bubble);
    
    setBubbles(prevBubbles => prevBubbles.map(b => 
      b.id === bubble.id ? { ...b, status: isCorrect ? 'correct' : 'incorrect' } : b
    ));

    if (isCorrect) {
      setScore(prevScore => prevScore + 1);
    } else {
      setScore(prevScore => Math.max(0, prevScore - 1));
    }
  };

  useEffect(() => {
    if (gameActive && bubbles.length > 0) {
      const remainingCorrectBubbles = bubbles.filter(b => isCorrectBubble(b) && b.status !== 'correct');
      
      if (remainingCorrectBubbles.length === 0) {
        // No more correct bubbles to click, generate a new round
        generateNewRound();
      }
    }
  }, [bubbles, isCorrectBubble, generateNewRound, gameActive]);

  const renderBubbleContent = (bubble) => {
    if (bubble.status === 'correct') {
      return <span className={styles.tick}>✓</span>;
    } else if (bubble.status === 'incorrect') {
      return <span className={styles.cross}>✗</span>;
    } else {
      return bubble.type !== 'color' ? bubble.value : null;
    }
  };

  const startGame = () => {
    generateNewRound();
    setScore(0);
    setTimeLeft(60);
    setGameActive(true);
    setShowPrizeModal(false);
    setIsWin(false);
    setEarnedReward(null);
  };

  return (
    <div>
      {!showPrizeModal ? (
        <div className={`${styles.gameContainer} ${visible ? styles.visible : ''}`}>
          <h2 className={styles.title}>Bubble Pop</h2>
          <p className={styles.instructions}>
            {gameActive ? rule : `Pop the correct bubbles! You need ${requiredScore} points to win. Click Start to begin.`}
          </p>
          {!gameActive && (
            <button className={styles.startButton} onClick={startGame}>
              Start Game
            </button>
          )}
          {gameActive && (
            <>
              <div className={styles.gameBoard}>
                {bubbles.map(bubble => (
                  <div
                    key={bubble.id}
                    className={`${styles.bubble} ${bubble.status ? styles[bubble.status] : ''}`}
                    style={{ 
                      backgroundColor: bubble.type === 'color' ? bubble.value : 
                                       bubble.status ? 'white' : undefined 
                    }}
                    onClick={() => handleBubbleClick(bubble)}
                  >
                    {renderBubbleContent(bubble)}
                  </div>
                ))}
              </div>
              <div className={styles.gameInfo}>
                Score: {score} | Time Left: {timeLeft}s
              </div>
            </>
          )}
        </div>
      ) : (
        <PrizeModal 
          isOpen={showPrizeModal} 
          onClose={startGame} 
          prize={prize}
          isWin={isWin}
          playerGold={playerState.gold}
          earnedReward={earnedReward}
        />
      )}
    </div>
  );
};

export default BubblePopLogic;