import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './WishingWell.module.css';
import GameWrapper from './GameWrapper';
import { usePlayerState } from '../context/PlayerStateContext';

const WishingWell = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newArtifact, setNewArtifact] = useState(null);
  const { playerState, setPlayerState } = usePlayerState();

  const goHome = () => {
    navigate('/home');
  };

  const openModal = () => {
    setIsModalOpen(true);
    setErrorMessage('');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage('');
  };

  const handlePayGold = () => {
    if (playerState.gold >= 100) {
      // Here you would add logic to get a new artifact
      const artifact = { name: "Artifacts coming soon! You have not been charged for 100 gold!" }; // Replace with actual artifact generation
      setPlayerState(prevState => ({
        ...prevState,
        gold: prevState.gold - 0,
        artifacts: [...prevState.artifacts, artifact]
      }));
      closeModal();
      setNewArtifact(artifact);
      setIsSuccessModalOpen(true);
    } else {
      setErrorMessage("Not enough gold!");
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setNewArtifact(null);
  };

  return (
    <GameWrapper backgroundImage="/WishingWell.webp">
      <button onClick={goHome} className={styles.homeButton}>Home</button>
      <button onClick={openModal} className={styles.findArtifactButton}>Find Artifact</button>
      
      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <h2>Make a wish to find a new artifact?</h2>
            <p className={styles.goldInfo}>Your current gold: {playerState.gold}</p>
            <div className={styles.errorMessageContainer}>
              {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
            </div>
            <div className={styles.modalButtons}>
              <button
                onClick={handlePayGold}
                className={`${styles.modalButton} ${styles.payButton}`}
              >
                Pay 100 Gold
              </button>
              <button
                onClick={closeModal}
                className={`${styles.modalButton} ${styles.noButton}`}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {isSuccessModalOpen && newArtifact && (
        <div className={styles.modalOverlay}>
          <div className={styles.successModal}>
            <h2>Congratulations!</h2>
            <p>You have successfully found a new artifact:</p>
            <h3 className={styles.artifactNameReveal}>{newArtifact.name}</h3>
            {/* You can add more artifact details here */}
            <button
              onClick={closeSuccessModal}
              className={`${styles.modalButton} ${styles.okButton}`}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </GameWrapper>
  );
};

export default WishingWell;