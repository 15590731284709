import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './MapPage.module.css';
import GameWrapper from './GameWrapper'; 

const MapPage = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/home');
  };

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <GameWrapper backgroundImage="/Map2.jpg">
      <button onClick={goHome} className={styles.homeButton}>Home</button>
      <button
        onClick={() => handleButtonClick('/castle-front')}
        className={`${styles.invisibleButton} ${styles.button1}`}
      >
        <span className={styles.hoverText}>Castle Front</span>
      </button>

      <button
        onClick={() => handleButtonClick('/silver-lake')}
        className={`${styles.invisibleButton} ${styles.button2}`}
      >
        <span className={styles.hoverText}>Silver Lake</span>
      </button>

      <button
        onClick={() => handleButtonClick('/kings-guard')}
        className={`${styles.invisibleButton} ${styles.button3}`}
      >
        <span className={styles.hoverText}>King's Guard</span>
      </button>

      <button
        onClick={() => handleButtonClick('/waterfall')}
        className={`${styles.invisibleButton} ${styles.button4}`}
      >
        <span className={styles.hoverText}>Waterfall</span>
      </button>

      <button
        onClick={() => handleButtonClick('/water-chapel')}
        className={`${styles.invisibleButton} ${styles.button5}`}
      >
        <span className={styles.hoverText}>Water Chapel</span>
      </button>

      <button
        onClick={() => handleButtonClick('/noble-village')}
        className={`${styles.invisibleButton} ${styles.button6}`}
      >
        <span className={styles.hoverText}>Noble Village</span>
      </button>

      <button
        onClick={() => handleButtonClick('/enchanted-fountain')}
        className={`${styles.invisibleButton} ${styles.button7}`}
      >
        <span className={styles.hoverText}>Enchanted Fountain</span>
      </button>
      <button
        onClick={() => handleButtonClick('/castle-great-room')}
        className={`${styles.invisibleButton} ${styles.button8}`}
      >
        <span className={styles.hoverText}>Great Room</span>
      </button>
      <button
        onClick={() => handleButtonClick('/castle-tower-room')}
        className={`${styles.invisibleButton} ${styles.button9}`}
      >
        <span className={styles.hoverText}>Tower Room</span>
      </button>
      <button
        onClick={() => handleButtonClick('/atlantis-retreat')}
        className={`${styles.invisibleButton} ${styles.button10}`}
      >
        <span className={styles.hoverText}>Atlantis Retreat</span>
      </button>
    </GameWrapper>
  );
};

export default MapPage;