import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CottageStorage.module.css';
import GameWrapper from './GameWrapper';
const CottageStorage = () => {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <GameWrapper backgroundImage='ChestRoomLocked.webp'>
      <button
          onClick={() => handleButtonClick('/home')}  /* You can assign any path you want here */
          className={`${styles.button1} ${styles.button}`}
        >
          Home
        </button>
        <button
          onClick={() => handleButtonClick('/cottage-potions')}  /* You can assign any path you want here */
          className={`${styles.button2} ${styles.button}`}
        >
          Items
        </button>
        <button
          onClick={() => handleButtonClick('/cottage-bedroom')}  /* You can assign any path you want here */
          className={`${styles.button3} ${styles.button}`}
        >
          Bedroom
        </button>
        <button
          onClick={() => handleButtonClick('/cottage-spells')}  /* You can assign any path you want here */
          className={`${styles.button4} ${styles.button}`}
        >
          Spells
        </button>

        <button
          onClick={() => handleButtonClick('/cottage-trap-door')}  /* You can assign any path you want here */
          className={`${styles.button5} ${styles.invisibleButton}`}
        >
        </button>
        </GameWrapper>
  );
};

export default CottageStorage;
