import React from 'react';
import styles from './PrizeModal.module.css';

const PrizeModal = ({ isOpen, onClose, prize, isWin }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <h2>{isWin ? "Congratulations!" : "Game Over!"}</h2>
        <p>Your final score: {prize.score}</p>
        {isWin ? (
          <>
            <div className={styles.prizeContainer}>
              <div className={styles.imageContainer}>
                <img src={prize.goldImagePath} alt="Gold" className={styles.prizeImage} />
                <div className={styles.imageText}>
                  <p>{prize.gold} Gold</p>
                </div>
              </div>
              {prize.item && (
                <div className={styles.imageContainer}>
                  <img src={prize.item.imagePath} alt={prize.item.name} className={styles.prizeImage} />
                  <div className={styles.imageText}>
                    <p>{prize.item.name}</p>
                  </div>
                </div>
              )}
            </div>
            <p>You have gained {prize.experience} experience!</p>
          </>
        ) : (
          <p>Better luck next time!</p>
        )}
        <button onClick={onClose}>Play Again</button>
      </div>
    </div>
  );
};

export default PrizeModal;