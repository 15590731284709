export const mapPuzzles = {
  noble_lands: {
    puzzles: [
      {
        completedCount: 0,
        title: "Speed Math",
        description: "A math puzzle game",
        location: "Noble Village",
        id : 1
      },
      {
        completedCount: 0,
        title: "Shape Sorter",
        description: "Identify the shapes",
        location: "Water Chapel",
        id : 2
      },
      {
        completedCount: 0,
        title: "Gem Crusher",
        description: "Fast pace gem crushing game",
        location: "King's Guard",
        id : 3
      },
      {
        completedCount: 0,
        title: "Order Memory Game",
        description: "Remember the order of the ingredients",
        location: "Silver Lake",
        id : 4
      },
      { 
        completedCount: 0,
        title: "Memory Game",
        description: "Classic memory game",
        location: "Enchanted Fountain",
        id : 5
      },
      {
        completedCount: 0,
        title: "Counting Challenge",
        description: "Count the number of moving objects before the time is up",
        location: "Castle front",
        id : 6
      },
      {
        completedCount: 0,
        title: "Cipher Text",
        description: "Decode the message before the time is up",
        location: "Waterfall",
        id : 7
      },
      {
        completedCount: 0,
        title: "Roman Numerals",
        description: "Identify the Roman Numerals",
        location: "Castle Great Room",
        id : 8
      },
      {
        completedCount: 0,
        title: "Word Finder",
        description: "Use the letters to find the correct word",
        location: "Castle Tower Room",
        id : 9
      },
      {
        completedCount: 0,
        title: "Bubble Pop",
        description: "Pop the correct balloons to score points",
        location: "Atlantic Retreat",
        id : 10
      },
    ],
  },
};

export function incrementPuzzleCompletionCount(mapId, puzzleId) {
  if (mapPuzzles[mapId] && mapPuzzles[mapId].puzzles[puzzleId]) {
    mapPuzzles[mapId].puzzles[puzzleId].completedCount++;
  }
}

export function getPuzzleCompletionCount(mapId, puzzleId) {
  if (mapPuzzles[mapId] && mapPuzzles[mapId].puzzles[puzzleId]) {
    return mapPuzzles[mapId].puzzles[puzzleId].completedCount;
  }
  return 0;
}
