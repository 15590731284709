import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CastleTowerRoom.module.css";
import GameTemplate from "../games/GameTemplate";
import WordJumbleGame from "../games/WordJumbleGame";
import GameWrapper from "../GameWrapper";
const CastleTowerRoom = () => {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <GameWrapper backgroundImage="/CastleTowerRoom.webp">
      <button
        onClick={() => handleButtonClick("/home")}
        className={`${styles.homeButton} ${styles.button}`}
      >
        Home
      </button>

      <button
        onClick={() => handleButtonClick("/map")}
        className={`${styles.mapButton} ${styles.button}`}
      >
        Map
      </button>

      <WordJumbleGame />
    </GameWrapper>
  );
};

export default CastleTowerRoom;
