import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CastleGreatRoom.module.css";
import GameTemplate from "../games/GameTemplate";
import RomanNumeralGame from "../games/RomanNumerals";
import GameWrapper from "../GameWrapper";
const CastleGreatRoom = () => {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <GameWrapper backgroundImage="/CastleGreatRoom.webp">
      <button
        onClick={() => handleButtonClick("/home")}
        className={`${styles.homeButton} ${styles.button}`}
      >
        Home
      </button>

      <button
        onClick={() => handleButtonClick("/map")}
        className={`${styles.mapButton} ${styles.button}`}
      >
        Map
      </button>

      <RomanNumeralGame/>
    </GameWrapper>
  );
};

export default CastleGreatRoom;
