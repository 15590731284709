import React, { useState, useEffect } from 'react';
import { usePlayerState, generateRandomItem } from '../../context/PlayerStateContext';
import styles from './RewardModal.module.css';
import { mapItems } from '../../constants/itemConstants';

const RewardModal = ({ isOpen, onClose, rewardType }) => {
  const { playerState, setPlayerState, addItem } = usePlayerState();
  const [rewardItems, setRewardItems] = useState([]);

  useEffect(() => {
    if (isOpen) {
      generateRewards();
    }
  }, [isOpen]);

  const generateRewards = () => {
    const goldReward = 200;
    const newItems = [];
    const mapId = 'noble_lands';
    const mapData = mapItems.find(map => map.id === mapId);
    while (newItems.length < 10) {
      const newItem = generateRandomItem(mapData.items);
      if (!newItems.some(item => item.name === newItem.name)) {
        newItems.push(newItem);
      }
    }

    setRewardItems(newItems);

    setPlayerState(prevState => ({
      ...prevState,
      gold: prevState.gold + goldReward,
      [rewardType + 'LevelRewards']: prevState[rewardType + 'LevelRewards'].map(reward => 
        !reward.rewardClaimed ? { ...reward, rewardClaimed: true } : reward
      )
    }));

    // Use addItem function to add items to the player's inventory
    newItems.forEach(item => {
      addItem(item);
    });
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <h2>Congratulations!</h2>
        <p>You've claimed your {rewardType === 'player' ? 'Player' : 'Puzzle'} Level Reward!</p>
        <div className={styles.rewardContainer}>
          {rewardItems.map((item, index) => (
            <div key={index} className={styles.imageContainer}>
              <img src={item.imagePath} alt={item.name} className={styles.rewardImage} />
              <div className={styles.imageText}>
                <p>{item.name}</p>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.goldContainer}>
          <img src="/Icons/goldIcon.webp" alt="Gold" className={styles.goldImage} />
          <div className={styles.goldText}>
            <p>200 Gold</p>
          </div>
        </div>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default RewardModal;