import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './WhackaMole.module.css';
import { usePlayerState, generateRandomGold, generateRandomItem } from '../../context/PlayerStateContext';
import { mapItems } from '../../constants/itemConstants';
import PrizeModal from '../additional/PrizeModal';

const WhackAMole = () => {
  const { playerState, setPlayerState, addItem, incrementPuzzleCompletionCount } = usePlayerState();
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(10);
  const [gameActive, setGameActive] = useState(false);
  const [activeMole, setActiveMole] = useState(null);
  const [visible, setVisible] = useState(false);
  const [showPrizeModal, setShowPrizeModal] = useState(false);
  const [prize, setPrize] = useState({ gold: 0, item: null, score: 0 });
  const [isWin, setIsWin] = useState(false);
  const [earnedReward, setEarnedReward] = useState(null);

  const timerRef = useRef(null);
  const moleTimerRef = useRef(null);
  const scoreRef = useRef(0);
  const gameActiveRef = useRef(false);
  const requiredScore = 20;

  const gemstones = [
    { name: 'Ruby', image: '/Gemstones/Ruby.png' },
    { name: 'Sapphire', image: '/Gemstones/Sapphire.png' },
    { name: 'Emerald', image: '/Gemstones/Emerald.png' },
    { name: 'Diamond', image: '/Gemstones/Diamond.png' },
    { name: 'Amethyst', image: '/Gemstones/Amethyst.png' },
    { name: 'Topaz', image: '/Gemstones/Topaz.png' },
    { name: 'Opal', image: '/Gemstones/Opal.png' },
    { name: 'Peridot', image: '/Gemstones/Peridot.png' },
    { name: 'Garnet', image: '/Gemstones/Garnet.png' },
  ];

  useEffect(() => {
    const timer = setTimeout(() => setVisible(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const earnReward = useCallback(() => {
    const gold = generateRandomGold();
    const mapId = 'noble_lands';
    const mapData = mapItems.find(map => map.id === mapId);
    const item = generateRandomItem(mapData.items);
    return { gold, item };
  }, []);

  const endGame = useCallback(() => {
    gameActiveRef.current = false;
    setGameActive(false);
    clearInterval(timerRef.current);
    clearTimeout(moleTimerRef.current);

    const earnedPrize = earnReward();
    setEarnedReward(earnedPrize);
    const finalScore = scoreRef.current;
    const isWinner = finalScore >= requiredScore;

    setPrize({
      gold: isWinner ? earnedPrize.gold : 0,
      item: isWinner ? earnedPrize.item : null,
      score: finalScore,
      goldImagePath: "/gold.png",
      experience: isWinner ? 0.01 : 0
    });

    setShowPrizeModal(true);
    setIsWin(isWinner);

    if (isWinner) {
      setPlayerState(prevState => ({
        ...prevState,
        gold: prevState.gold + earnedPrize.gold,
        level: prevState.level + 0.01
      }));

      incrementPuzzleCompletionCount('noble_lands', 2);
      
      if (earnedPrize.item) {
        addItem(earnedPrize.item);
      }
    }
  }, [earnReward, incrementPuzzleCompletionCount, addItem, setPlayerState]);

  const popMole = useCallback(() => {
    if (!gameActiveRef.current) return;

    const randomMole = Math.floor(Math.random() * gemstones.length);
    setActiveMole(randomMole);

    clearTimeout(moleTimerRef.current);
    moleTimerRef.current = setTimeout(() => {
      setActiveMole(null);
      if (gameActiveRef.current) {
        popMole();
      }
    }, Math.random() * 1000 + 500);
  }, [gemstones.length]);

  const startGame = useCallback(() => {
    scoreRef.current = 0;
    setScore(0);
    setTimeLeft(60);
    setGameActive(true);
    gameActiveRef.current = true;
    setActiveMole(null);
    setShowPrizeModal(false);
    setIsWin(false);
    setEarnedReward(null);

    clearInterval(timerRef.current);
    clearTimeout(moleTimerRef.current);

    timerRef.current = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          clearTimeout(moleTimerRef.current);
          endGame();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    popMole();
  }, [endGame, popMole]);

  const whackMole = useCallback((index) => {
    if (index === activeMole && gameActiveRef.current) {
      scoreRef.current += 1;
      setScore(scoreRef.current);
      setActiveMole(null);
      clearTimeout(moleTimerRef.current);
      popMole();
    }
  }, [activeMole, popMole]);

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current);
      clearTimeout(moleTimerRef.current);
    };
  }, []);

  return (
    <div>
      {!showPrizeModal ? (
        <div className={`${styles.gameContainer} ${visible ? styles.visible : ''}`}>
          <h2 className={styles.title}>Gem Crusher</h2>
          <p className={styles.instructions}>
            Click on the gemstones as they appear to score points! You have 60 seconds to score {requiredScore} points.
          </p>
          {!gameActive && (
            <button className={styles.startButton} onClick={startGame}>
              Start Game
            </button>
          )}
          {gameActive && (
            <>
              <div className={styles.gameInfo}>
                Score: {score} | Time Left: {timeLeft}s
              </div>
              <div className={styles.gameBoard}>
                {gemstones.map((gemstone, index) => (
                  <div
                    key={index}
                    className={`${styles.hole} ${activeMole === index ? styles.active : ''}`}
                    onClick={() => whackMole(index)}
                  >
                    {activeMole === index && (
                      <div className={styles.mole}>
                        <img src={gemstone.image} alt={gemstone.name} className={styles.gemstoneImage} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      ) : (
        <PrizeModal 
          isOpen={showPrizeModal} 
          onClose={startGame} 
          prize={prize}
          isWin={isWin}
          playerGold={playerState.gold}
          earnedReward={earnedReward}
        />
      )}
    </div>
  );
};

export default WhackAMole;