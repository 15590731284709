import React, { useState, useEffect, useCallback } from 'react';
import { usePlayerState, generateRandomGold, generateRandomItem } from '../../context/PlayerStateContext';
import { mapItems } from '../../constants/itemConstants';
import PrizeModal from '../additional/PrizeModal';
import styles from './MagicalSequenceGame.module.css';

const ingredients = [
  { name: "Ancient Leaves", imagePath: "/Ingredients/Ancient Leaves.png" },
  { name: "Ancient Oak Bark", imagePath: "/Ingredients/Ancient Oak Bark.png" },
  { name: "Ants", imagePath: "/Ingredients/Ants.png" },
  { name: "Baby Frogs", imagePath: "/Ingredients/Baby Frogs.png" },
  { name: "Bats", imagePath: "/Ingredients/Bats.png" },
  { name: "Blue Ants", imagePath: "/Ingredients/Blue Ants.png" },
  { name: "Blue Eggs", imagePath: "/Ingredients/Blue Eggs.png" },
  { name: "Blue Feathers", imagePath: "/Ingredients/Blue Feathers.png" },
  { name: "Butterflies", imagePath: "/Ingredients/Butterflies.png" },
  { name: "Caterpillars", imagePath: "/Ingredients/Caterpillars.png" },
  { name: "Christmas Beetles", imagePath: "/Ingredients/Christmas Beetles.png" },
  { name: "Cinimon Scrolls", imagePath: "/Ingredients/Cinimon Scrolls.png" },
];

const initialGameState = {
  sequence: [],
  currentLevel: [],
  userSequence: [],
  showSequence: false,
  canClick: false,
  gameActive: false,
  showPrizeModal: false,
  prize: { gold: 0, item: null, score: 0 },
  isWin: false,
  timeLeft: 120,
  gameOverState: null,
  failedIndex: -1,
};

const MagicalSequenceGame = () => {
  const { playerState, setPlayerState, addItem, incrementPuzzleCompletionCount } = usePlayerState();
  const [gameState, setGameState] = useState(initialGameState);
  const [visible, setVisible] = useState(false);
  const [earnedReward, setEarnedReward] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let timer;
    if (gameState.gameActive && gameState.timeLeft > 0) {
      timer = setInterval(() => {
        setGameState(prevState => ({
          ...prevState,
          timeLeft: prevState.timeLeft - 1,
        }));
      }, 1000);
    } else if (gameState.timeLeft === 0 && gameState.gameActive) {
      endGame(gameState.sequence.length >= 10);
    }
    return () => clearInterval(timer);
  }, [gameState.timeLeft, gameState.gameActive, gameState.sequence.length]);

  useEffect(() => {
    if (gameState.gameOverState !== null) {
      const timer = setTimeout(() => {
        showReward(gameState.gameOverState);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [gameState.gameOverState]);

  const earnReward = useCallback(() => {
    const gold = generateRandomGold();
    const mapId = 'noble_lands';
    const mapData = mapItems.find(map => map.id === mapId);
    const item = generateRandomItem(mapData.items);
    return { gold, item };
  }, []);

  const generateSequence = useCallback(() => {
    setGameState(prevState => {
      const newSequence = [...prevState.sequence, Math.floor(Math.random() * ingredients.length)];
      return {
        ...prevState,
        sequence: newSequence,
        currentLevel: newSequence,
        showSequence: true,
        userSequence: [],
        failedIndex: -1,
      };
    });

    setTimeout(() => {
      setGameState(prevState => ({
        ...prevState,
        showSequence: false,
        canClick: true,
      }));
    }, 2000);
  }, []);

  const handleImageClick = (index) => {
    if (!gameState.canClick) return;

    setGameState(prevState => {
      const newUserSequence = [...prevState.userSequence, index];
      
      if (index !== prevState.currentLevel[prevState.userSequence.length]) {
        // Wrong answer
        endGame(prevState.sequence.length >= 6);
        return {
          ...prevState,
          userSequence: newUserSequence,
          canClick: false,
          failedIndex: prevState.userSequence.length,
          gameOverState: false,
        };
      } else if (newUserSequence.length === prevState.currentLevel.length) {
        if (prevState.sequence.length === 24) {
          endGame(true);
          return {
            ...prevState,
            userSequence: newUserSequence,
            canClick: false,
            gameOverState: true,
          };
        } else {
          setTimeout(generateSequence, 1000);
          return {
            ...prevState,
            userSequence: newUserSequence,
            canClick: false,
          };
        }
      }

      return {
        ...prevState,
        userSequence: newUserSequence,
      };
    });
  };

  const startGame = () => {
    setGameState({
      ...initialGameState,
      gameActive: true,
    });
    generateSequence();
  };

  const endGame = (win) => {
    setGameState(prevState => ({
      ...prevState,
      gameActive: false,
      gameOverState: win,
    }));
  };

  const showReward = (win) => {
    let earnedPrize = { gold: 0, item: null, experience: 0 };
    if (win) {
      const baseGold = generateRandomGold();
      const baseExperience = 0.02;
      const level = gameState.sequence.length;
      
      // Calculate additional rewards
      const additionalLevels = Math.max(0, level - 6);
      const additionalGold = Math.floor(additionalLevels / 2) + 3; // +3 for winning, +1 for every 2 levels above 10
      const additionalExperience = (Math.floor(additionalLevels / 2) * 0.01) + 0.01; // +0.01 for winning, +0.01 for every 2 levels above 10

      earnedPrize = {
        gold: baseGold + additionalGold,
        item: generateRandomItem(mapItems.find(map => map.id === 'noble_lands').items),
        experience: baseExperience + additionalExperience
      };
      
      setPlayerState(prevState => ({
        ...prevState,
        gold: prevState.gold + earnedPrize.gold,
        level: prevState.level + earnedPrize.experience,
      }));

      incrementPuzzleCompletionCount('noble_lands', 3);
      addItem(earnedPrize.item);
    }

    setEarnedReward(earnedPrize);

    setGameState(prevState => ({
      ...prevState,
      showPrizeModal: true,
      prize: {
        gold: earnedPrize.gold,
        item: earnedPrize.item,
        score: prevState.sequence.length,
        goldImagePath: "/gold.png",
        experience: earnedPrize.experience
      },
      isWin: win,
    }));
  };

  if (gameState.showPrizeModal) {
    return (
      <PrizeModal 
        isOpen={gameState.showPrizeModal} 
        onClose={startGame} 
        prize={gameState.prize}
        isWin={gameState.isWin}
        playerGold={playerState.gold}
        earnedReward={earnedReward}
      />
    );
  }

  return (
    <div className={`${styles.gameContainer} ${visible ? styles.visible : ''}`}>
      <h2 className={styles.title}>Ingredients Sequence Game</h2>
      <div className={styles.gameContent}>
        <div className={styles.gameArea}>
          <p className={styles.instructions}>
            Remember the sequence of ingredients. Click them in the correct order.
            Get to 24 or as far as you can in 120 seconds! Minimum score: 6
          </p>
          <div className={styles.sequenceGrid}>
            {Array(24).fill(null).map((_, i) => (
              <div key={i} className={styles.sequenceItem}>
                {i < gameState.currentLevel.length && (
                  gameState.showSequence || 
                  i < gameState.userSequence.length || 
                  (gameState.failedIndex !== -1 && i >= gameState.failedIndex)
                ) && (
                  <img 
                    src={ingredients[gameState.currentLevel[i]].imagePath} 
                    alt={ingredients[gameState.currentLevel[i]].name} 
                    className={styles.sequenceImage} 
                  />
                )}
                {i < gameState.userSequence.length && (
                  <div className={`${styles.resultDot} ${
                    i === gameState.failedIndex 
                      ? styles.incorrect
                      : styles.correct
                  }`} />
                )}
              </div>
            ))}
          </div>
          <div className={styles.gameInfo}>
            <span>Level: {gameState.sequence.length}</span>
            <span>Time Left: {gameState.timeLeft}s</span>
          </div>
          {!gameState.gameActive && gameState.gameOverState === null && (
            <button className={styles.startButton} onClick={startGame}>
              {gameState.sequence.length === 0 ? "Start Game" : "Play Again"}
            </button>
          )}
          {gameState.gameOverState !== null && (
            <p className={styles.gameOverMessage}>
              {gameState.gameOverState ? "Congratulations! You won!" : "Game Over!"}
            </p>
          )}
        </div>
        <div className={styles.imageTable}>
          {ingredients.map((ingredient, index) => (
            <img
              key={index}
              src={ingredient.imagePath}
              alt={ingredient.name}
              onClick={() => handleImageClick(index)}
              className={styles.tableImage}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MagicalSequenceGame;