import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ImagePreloaderFunc.module.css';
import GameWrapper from '../components/GameWrapper';

function useImagePreloader(imageUrls) {
  const [progress, setProgress] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);

  useEffect(() => {
    console.log('Starting to preload images...');
    let loadedCount = 0;
    const totalImages = imageUrls.length;
    const startTime = Date.now();

    const updateProgress = () => {
      loadedCount++;
      const newProgress = Math.round((loadedCount / totalImages) * 100);
      setProgress(newProgress);
      
      const currentTime = Date.now();
      const elapsed = (currentTime - startTime) / 1000;
      setTimeElapsed(elapsed);

      console.log(`Progress: ${newProgress}% | Time elapsed: ${elapsed.toFixed(2)} seconds`);
    };

    imageUrls.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = updateProgress;
      img.onerror = () => {
        console.error(`Failed to load image: ${src}`);
        updateProgress();
      };
    });
  }, [imageUrls]);

  return { progress, timeElapsed };
}

function ImagePreloader({ imageUrls }) {
  const { progress, timeElapsed } = useImagePreloader(imageUrls);
  const navigate = useNavigate();

  useEffect(() => {
    if (progress === 100) {
      console.log(`Images finished preloading in ${timeElapsed.toFixed(2)} seconds, navigating to home`);
      navigate('/home');
    }
  }, [progress, navigate, timeElapsed]);

  return (
    <GameWrapper backgroundImage="/Landing.webp">
      <div className={styles.loadingBox}>
        <h2 className={styles.title}>Loading Game Assets</h2>
        <div className={styles.progressBarContainer}>
          <div 
            className={styles.progressBar}
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <p className={styles.progressText}>
          {progress}% Complete
        </p>
        <p className={styles.timeElapsed}>
          Time elapsed: {timeElapsed.toFixed(2)} seconds
        </p>
        <p className={styles.preparingText}>
          Preparing your magical adventure...
        </p>
      </div>
    </GameWrapper>
  );
}

export default ImagePreloader;