import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./EnchantedFountain.module.css";
import GameTemplate from "../games/GameTemplate";
import MemoryGame from "../games/MemoryGame";
import GameWrapper from "../GameWrapper";
const EnchantedFountain = () => {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <GameWrapper backgroundImage="/EnchantedFountain.webp">
      <button
        onClick={() => handleButtonClick("/home")}
        className={`${styles.homeButton} ${styles.button}`}
      >
        Home
      </button>

      <button
        onClick={() => handleButtonClick("/map")}
        className={`${styles.mapButton} ${styles.button}`}
      >
        Map
      </button>

      <MemoryGame />
    </GameWrapper>
  );
};

export default EnchantedFountain;
