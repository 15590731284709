import React, { useReducer, useEffect, useCallback } from 'react';
import styles from './WordJumbleGame.module.css';
import { usePlayerState, generateRandomGold, generateRandomItem } from '../../context/PlayerStateContext';
import { mapItems } from '../../constants/itemConstants';
import PrizeModal from '../additional/PrizeModal';

const words = [
    { word: 'BICYCLE', clue: 'A two-wheeled vehicle you pedal' },
    { word: 'RAINBOW', clue: 'A colorful arc in the sky after rain' },
    { word: 'PIZZA', clue: 'A round, flat bread with toppings' },
    { word: 'DRAGON', clue: 'A mythical fire-breathing creature' },
    { word: 'SOCCER', clue: 'A popular sport played with a round ball' },
    { word: 'PUPPY', clue: 'A baby dog' },
    { word: 'CASTLE', clue: 'A large building with towers and walls' },
    { word: 'PIRATE', clue: 'A person who robs ships at sea' },
    { word: 'ROCKET', clue: 'A vehicle that goes to space' },
    { word: 'MERMAID', clue: 'A mythical sea creature, half-woman, half-fish' },
    { word: 'COOKIE', clue: 'A sweet, flat cake' },
    { word: 'DINOSAUR', clue: 'A giant extinct reptile' },
    { word: 'MAGIC', clue: 'Tricks that seem impossible' },
    { word: 'MONKEY', clue: 'An animal that likes bananas and climbing' },
    { word: 'ROBOT', clue: 'A machine that acts like a person' },
    { word: 'TREASURE', clue: 'Valuable things, often hidden' },
    { word: 'CIRCUS', clue: 'A traveling show with animals and acrobats' },
    { word: 'KNIGHT', clue: 'A person in armor who serves a king' },
    { word: 'CANDY', clue: 'Sweet treats' },
    { word: 'UNICORN', clue: 'A magical horse with a horn' },
    { word: 'CARTOON', clue: 'A funny animated show' },
    { word: 'MUSIC', clue: 'Sounds that are nice to listen to' },
    { word: 'BALLOON', clue: 'A colorful inflatable toy' },
    { word: 'PUZZLE', clue: 'A game that makes you think' },
    { word: 'HOCKEY', clue: 'An ice sport with sticks and a puck' },
    { word: 'DOLPHIN', clue: 'A friendly, smart sea animal' },
    { word: 'MONSTER', clue: 'A scary, imaginary creature' },
    { word: 'COMIC', clue: 'A book with pictures that tell a story' },
    { word: 'PENGUIN', clue: 'A bird that swims but can\'t fly' },
    { word: 'BEACH', clue: 'A sandy shore by the ocean' },
    { word: 'GUITAR', clue: 'A musical instrument with strings' },
    { word: 'CAMERA', clue: 'A device for taking pictures' },
    { word: 'JUNGLE', clue: 'A thick, tropical forest' },
    { word: 'VOLCANO', clue: 'A mountain that can erupt' },
    { word: 'PLANET', clue: 'A large object orbiting a star' },
    { word: 'WIZARD', clue: 'A person who can do magic' },
    { word: 'GHOST', clue: 'A spooky, transparent spirit' },
    { word: 'BRIDGE', clue: 'A structure that goes over water' },
    { word: 'ROLLERCOASTER', clue: 'An exciting amusement park ride' },
    { word: 'ALIEN', clue: 'A being from another planet' },
    { word: 'TELESCOPE', clue: 'A tool for looking at stars' },
    { word: 'OCTOPUS', clue: 'A sea animal with eight arms' },
    { word: 'ALPHABET', clue: 'All the letters used in a language' },
    { word: 'KANGAROO', clue: 'An animal that hops and has a pouch' },
    { word: 'ELEPHANT', clue: 'A large animal with a trunk' },
    { word: 'UMBRELLA', clue: 'A thing that keeps you dry in the rain' },
    { word: 'BUTTERFLY', clue: 'An insect with colorful wings' },
    { word: 'PARACHUTE', clue: 'A device for jumping safely from planes' },
    { word: 'WATERFALL', clue: 'Water falling over the edge of a cliff' },
    { word: 'BIRTHDAY', clue: 'The day you were born' },
    { word: 'FOOTBALL', clue: 'A sport played with an oval ball' },
    { word: 'COMPUTER', clue: 'An electronic device for work and games' },
    { word: 'POPCORN', clue: 'A snack often eaten at movies' },
    { word: 'SNOWMAN', clue: 'A figure made of snow' },
    { word: 'LIBRARY', clue: 'A place with many books to borrow' },
    { word: 'TIGER', clue: 'A big cat with orange and black stripes' },
    { word: 'PRINCESS', clue: 'A daughter of a king and queen' },
    { word: 'SKELETON', clue: 'The bones in your body' },
    { word: 'FIREWORKS', clue: 'Colorful explosions in the sky' },
    { word: 'RAINBOW', clue: 'A colorful arch in the sky after rain' },
    { word: 'HAMBURGER', clue: 'A popular sandwich with a meat patty' },
    { word: 'BACKPACK', clue: 'A bag you carry on your back' },
    { word: 'JELLYFISH', clue: 'A sea creature that can sting' },
    { word: 'AIRPLANE', clue: 'A vehicle that flies in the sky' },
    { word: 'SUBMARINE', clue: 'A ship that goes under water' },
    { word: 'RACCOON', clue: 'A small animal with a masked face' },
    { word: 'POTION', clue: 'A magical liquid in stories' },
    { word: 'RAINBOW', clue: 'Colorful arch in the sky after rain' },
    { word: 'PICNIC', clue: 'An outdoor meal' },
    { word: 'PAJAMAS', clue: 'Clothes you wear to sleep' },
    { word: 'ZEBRA', clue: 'An animal with black and white stripes' },
    { word: 'ISLAND', clue: 'Land surrounded by water' },
    { word: 'RAINBOW', clue: 'Colorful arch in the sky after rain' },
    { word: 'VOLCANO', clue: 'A mountain that erupts' },
    { word: 'DESERT', clue: 'A very dry, sandy place' },
    { word: 'CAMERA', clue: 'A device for taking photos' },
    { word: 'PENCIL', clue: 'A tool for writing or drawing' },
    { word: 'IGLOO', clue: 'A dome-shaped snow house' },
    { word: 'KITE', clue: 'A toy that flies in the wind' },
    { word: 'LEMON', clue: 'A sour, yellow fruit' },
    { word: 'MAGNET', clue: 'An object that attracts metal' },
    { word: 'NINJA', clue: 'A skilled Japanese warrior' },
    { word: 'OCEAN', clue: 'A very large body of salt water' },
    { word: 'PILLOW', clue: 'A soft cushion for your head' },
    { word: 'QUEEN', clue: 'A female ruler of a country' },
    { word: 'RIVER', clue: 'A large, flowing stream of water' },
    { word: 'SANDWICH', clue: 'Food between two slices of bread' },
    { word: 'TRAIN', clue: 'A vehicle that runs on tracks' },
    { word: 'VAMPIRE', clue: 'A mythical creature that drinks blood' },
    { word: 'WALLET', clue: 'A small case for money and cards' },
    { word: 'YOGURT', clue: 'A creamy food made from milk' },
    { word: 'ZOMBIE', clue: 'A fictional undead creature' },
    { word: 'BLANKET', clue: 'A cover to keep you warm' },
    { word: 'CRAYONS', clue: 'Colorful sticks for drawing' },
    { word: 'DOLPHIN', clue: 'A friendly sea mammal' },
    { word: 'ENERGY', clue: 'The power to do things' },
    { word: 'FINGER', clue: 'A part of your hand' },
    { word: 'GOGGLES', clue: 'Special glasses for swimming' },
    { word: 'HELMET', clue: 'Protective head gear' },
    { word: 'INSECT', clue: 'A small creature with six legs' }
  ];
  
  
  // Action types
  const ACTIONS = {
    SET_VISIBLE: 'SET_VISIBLE',
    START_GAME: 'START_GAME',
    END_GAME: 'END_GAME',
    GENERATE_NEW_WORD: 'GENERATE_NEW_WORD',
    HANDLE_LETTER_CLICK: 'HANDLE_LETTER_CLICK',
    DECREASE_TIME: 'DECREASE_TIME',
    SET_ERROR: 'SET_ERROR',
    CLEAR_ERROR: 'CLEAR_ERROR',
  };
  
  // Initial state
  const initialState = {
    currentWord: '',
    currentClue: '',
    jumbledLetters: [],
    userWord: [],
    score: 0,
    timeLeft: 60,
    gameActive: false,
    visible: false,
    showPrizeModal: false,
    prize: { gold: 0, item: null, score: 0 },
    isWin: false,
    error: null,
  };
  
  // Reducer function
  function reducer(state, action) {
    //console.log('Reducer called with action:', action.type, 'Payload:', action.payload);
    switch (action.type) {
      case ACTIONS.SET_VISIBLE:
        return { ...state, visible: true };
      case ACTIONS.START_GAME:
        return { 
          ...state, 
          score: 0, 
          timeLeft: 60, 
          gameActive: true, 
          showPrizeModal: false,
          error: null
        };
      case ACTIONS.END_GAME:
        return { 
          ...state, 
          gameActive: false, 
          showPrizeModal: true, 
          prize: action.payload.prize,
          isWin: action.payload.isWin
        };
      case ACTIONS.GENERATE_NEW_WORD:
        return { 
          ...state, 
          currentWord: action.payload.word,
          currentClue: action.payload.clue,
          jumbledLetters: action.payload.jumbledLetters,
          userWord: []
        };
      case ACTIONS.HANDLE_LETTER_CLICK:
        return {
          ...state,
          jumbledLetters: action.payload.newJumbledLetters,
          userWord: action.payload.newUserWord,
          score: action.payload.newScore
        };
      case ACTIONS.DECREASE_TIME:
        return { ...state, timeLeft: state.timeLeft - 1 };
      case ACTIONS.SET_ERROR:
        return { ...state, error: action.payload, gameActive: false };
      case ACTIONS.CLEAR_ERROR:
        return { ...state, error: null };
      default:
        return state;
    }
  }
  
  const WordJumbleGame = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { playerState, setPlayerState, addItem, incrementPuzzleCompletionCount } = usePlayerState();
  
    const requiredScore = 20;
  
    const jumbleWord = useCallback((word) => {
      console.log('jumbleWord called with word:', word);
      if (!word || typeof word !== 'string' || word.length === 0) {
        console.error('Invalid word provided to jumbleWord:', word);
        dispatch({ type: ACTIONS.SET_ERROR, payload: 'An error occurred while preparing the game. Please try again.' });
        return [];
      }
      return word.split('').map((letter, index) => ({ 
        id: `${word}-${index}`, 
        letter, 
        status: 'default'
      })).sort(() => Math.random() - 0.5);
    }, []);
  
    const generateNewWord = useCallback(() => {
      console.log('generateNewWord called');
      if (!Array.isArray(words) || words.length === 0) {
        console.error('Words array is invalid or empty');
        dispatch({ type: ACTIONS.SET_ERROR, payload: 'An error occurred while loading words. Please refresh the page.' });
        return;
      }
      const wordObj = words[Math.floor(Math.random() * words.length)];
      if (!wordObj || typeof wordObj.word !== 'string' || typeof wordObj.clue !== 'string') {
        console.error('Invalid word object selected:', wordObj);
        dispatch({ type: ACTIONS.SET_ERROR, payload: 'An error occurred while selecting a word. Please try again.' });
        return;
      }
      const jumbledLetters = jumbleWord(wordObj.word);
      dispatch({ 
        type: ACTIONS.GENERATE_NEW_WORD, 
        payload: { 
          word: wordObj.word, 
          clue: wordObj.clue, 
          jumbledLetters 
        } 
      });
    }, [jumbleWord]);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        dispatch({ type: ACTIONS.SET_VISIBLE });
      }, 1000);
  
      return () => clearTimeout(timer);
    }, []);
  
    const earnReward = useCallback(() => {
      const gold = generateRandomGold();
      const mapId = 'noble_lands';
      const mapData = mapItems.find(map => map.id === mapId);
      const item = generateRandomItem(mapData.items);
      return { gold, item };
    }, []);
  
    const endGame = useCallback(() => {
      console.log('endGame called');
      const earnedPrize = earnReward();
      const isWinner = state.score >= requiredScore;
      
      const prize = {
        gold: isWinner ? earnedPrize.gold : 0,
        item: isWinner ? earnedPrize.item : null,
        score: state.score,
        goldImagePath: "/gold.png",
        experience: isWinner ? 0.02 : 0
      };
  
      dispatch({ type: ACTIONS.END_GAME, payload: { prize, isWin: isWinner } });
  
      if (isWinner) {
        setPlayerState(prevState => ({
          ...prevState,
          gold: prevState.gold + earnedPrize.gold,
          level: prevState.level + 0.02,
        }));
  
        incrementPuzzleCompletionCount('noble_lands', 8);
        addItem(earnedPrize.item);
      }
    }, [state.score, requiredScore, earnReward, setPlayerState, addItem, incrementPuzzleCompletionCount]);
  
    const startGame = useCallback(() => {
      //console.log('startGame called');
      dispatch({ type: ACTIONS.START_GAME });
      generateNewWord();
    }, [generateNewWord]);
  
    useEffect(() => {
      let timer;
      if (state.gameActive && state.timeLeft > 0) {
        timer = setInterval(() => {
          dispatch({ type: ACTIONS.DECREASE_TIME });
        }, 1000);
      } else if (state.timeLeft === 0 && state.gameActive) {
        endGame();
      }
      return () => clearInterval(timer);
    }, [state.timeLeft, state.gameActive, endGame]);
  
    const handleLetterClick = (letterId) => {
      console.log('handleLetterClick called with letterId:', letterId);
      if (!state.gameActive || state.error) return;
  
      const letterIndex = state.jumbledLetters.findIndex(l => l.id === letterId);
      if (letterIndex === -1) return;
  
      const letter = state.jumbledLetters[letterIndex];
      if (letter.status === 'correct' || letter.status === 'disabled') return;
  
      const newJumbledLetters = [...state.jumbledLetters];
      let newUserWord = [...state.userWord];
      let newScore = state.score;
      
      if (state.currentWord[state.userWord.length] === letter.letter) {
        newJumbledLetters[letterIndex] = { ...letter, status: 'correct' };
        newUserWord.push(letter);
        newScore += 1;
  
        if (newUserWord.length === state.currentWord.length) {
          setTimeout(generateNewWord, 1000);
        }
      } else {
        newJumbledLetters[letterIndex] = { ...letter, status: 'incorrect' };
        newScore = Math.max(0, newScore - 1);
        setTimeout(() => {
          dispatch({
            type: ACTIONS.HANDLE_LETTER_CLICK,
            payload: {
              newJumbledLetters: newJumbledLetters.map(l => 
                l.id === letterId ? { ...l, status: 'default' } : l
              ),
              newUserWord,
              newScore
            }
          });
        }, 2000);
      }
  
      dispatch({
        type: ACTIONS.HANDLE_LETTER_CLICK,
        payload: { newJumbledLetters, newUserWord, newScore }
      });
    };
  
    useEffect(() => {
      if (state.gameActive && !state.error) {
        generateNewWord();
      }
    }, [state.gameActive, generateNewWord]);
  
    if (state.error) {
      return <div className={styles.error}>{state.error}</div>;
    }
  
    //console.log('Current state:', state);
  
    return (
      <div>
        {!state.showPrizeModal ? (
          <div className={`${styles.gameContainer} ${state.visible ? styles.visible : ''}`}>
            <h2 className={styles.title}>Word Jumble Game</h2>
            <p className={styles.instructions}>
              Unscramble the word. Score {requiredScore} points in 60 seconds to win!
            </p>
            <div className={styles.gameContent}>
              <p className={styles.clue}>Clue: {state.currentClue}</p>
              <div className={styles.wordArea}>
                {state.userWord.map((letter, index) => (
                  <span key={`user-${index}`} className={styles.letter}>{letter.letter}</span>
                ))}
                {state.currentWord && Array(state.currentWord.length - state.userWord.length).fill('_').map((_, index) => (
                  <span key={`empty-${index}`} className={styles.emptyLetter}>_</span>
                ))}
              </div>
              <div className={styles.jumbledArea}>
                {state.jumbledLetters.map(letter => (
                  <button 
                    key={letter.id} 
                    className={`${styles.letterButton} ${styles[letter.status]}`}
                    onClick={() => handleLetterClick(letter.id)}
                    disabled={letter.status === 'correct' || letter.status === 'disabled'}
                  >
                    {letter.letter}
                  </button>
                ))}
              </div>
              <div className={styles.gameInfo}>
                Score: {state.score} | Time Left: {state.timeLeft}s
              </div>
            </div>
            {!state.gameActive && (
              <button className={styles.startButton} onClick={startGame}>
                {state.timeLeft === 60 ? "Start Game" : "Play Again"}
              </button>
            )}
          </div>
        ) : (
          <PrizeModal 
            isOpen={state.showPrizeModal} 
            onClose={startGame} 
            prize={state.prize}
            isWin={state.isWin}
          />
        )}
      </div>
    );
  };
  
  export default WordJumbleGame;