import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CottageSpells.module.css";
import GameWrapper from "./GameWrapper";
import { usePlayerState } from "../context/PlayerStateContext";

const CottageSpells = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [currentRecipeIndex, setCurrentRecipeIndex] = useState(0);
  const { playerState, setPlayerState } = usePlayerState();
  const [message, setMessage] = useState("");
  const [createdRecipe, setCreatedRecipe] = useState(null);
  const [showCreated, setShowCreated] = useState(true);
  const [showNotCreated, setShowNotCreated] = useState(true);
  const [filteredRecipes, setFilteredRecipes] = useState([]);

  // Test variable: set to true to bypass ingredient checks
  const testMode = false;

  useEffect(() => {
    const newFilteredRecipes = playerState.recipes.filter(
      (recipe) =>
        (showCreated && recipe.created) || (showNotCreated && !recipe.created)
    );
    setFilteredRecipes(newFilteredRecipes);
    setCurrentRecipeIndex((prev) =>
      newFilteredRecipes.length > 0
        ? Math.min(prev, newFilteredRecipes.length - 1)
        : 0
    );
    setMessage(""); // Clear the message when filters change

    if (newFilteredRecipes.length === 0) {
      if (!showCreated && !showNotCreated) {
        setMessage("Please select at least one filter option.");
      } else if (!showCreated) {
        setMessage("You don't have any uncreated recipes.");
      } else if (!showNotCreated) {
        setMessage("You don't have any created recipes.");
      } else {
        setMessage("You don't have any recipes.");
      }
    }
  }, [playerState.recipes, showCreated, showNotCreated]);

  const handleButtonClick = (path) => {
    navigate(path);
  };

  const openModal = () => {
    setIsModalOpen(true);
    setMessage(""); // Clear the message when opening the modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setMessage(""); // Clear the message when closing the modal
  };

  const nextRecipe = () => {
    setCurrentRecipeIndex(
      (prevIndex) => (prevIndex + 1) % filteredRecipes.length
    );
    setMessage(""); // Clear the message when changing recipes
  };

  const previousRecipe = () => {
    setCurrentRecipeIndex(
      (prevIndex) =>
        (prevIndex - 1 + filteredRecipes.length) % filteredRecipes.length
    );
    setMessage(""); // Clear the message when changing recipes
  };

  const hasEnoughItems = (item) => {
    const playerItems = playerState[item.category];
    if (!playerItems) return false;

    return playerItems.some(
      (playerItem) =>
        playerItem.name === item.name && playerItem.amount >= item.amount
    );
  };

  const canCreateRecipe = () => {
    return filteredRecipes[currentRecipeIndex].items.every(hasEnoughItems);
  };

  const handleCreateRecipe = () => {
    const recipe = filteredRecipes[currentRecipeIndex];
    if (recipe.created) {
      setMessage("You've already created this recipe!");
      return;
    }

    if (canCreateRecipe() || testMode) {
      let updatedPlayerState = { ...playerState };

      if (!testMode) {
        // Deduct items from player's inventory
        recipe.items.forEach((item) => {
          const index = updatedPlayerState[item.category].findIndex(
            (i) => i.name === item.name
          );
          if (index !== -1) {
            updatedPlayerState[item.category][index].amount -= item.amount;
            if (updatedPlayerState[item.category][index].amount <= 0) {
              updatedPlayerState[item.category].splice(index, 1);
            }
          }
        });
      }

      // Update the recipe to mark it as created
      const updatedRecipes = [...updatedPlayerState.recipes];
      const recipeIndex = updatedRecipes.findIndex(
        (r) => r.name === recipe.name
      );
      updatedRecipes[recipeIndex] = {
        ...recipe,
        created: true,
        createdData: {
          imagePath: recipe.imagePath,
          description: recipe.description,
          stats: recipe.stats,
        },
      };

      updatedPlayerState = {
        ...updatedPlayerState,
        recipes: updatedRecipes,
      };

      setPlayerState(updatedPlayerState);
      setCreatedRecipe(updatedRecipes[recipeIndex]);
      setIsSuccessModalOpen(true);
      setIsModalOpen(false);
      setMessage("");
    } else {
      setMessage("You don't have all the items for this recipe!");
    }
  };

  const viewCreatedRecipe = () => {
    const recipe = filteredRecipes[currentRecipeIndex];
    setCreatedRecipe(recipe);
    setIsSuccessModalOpen(true);
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (type) => {
    if (type === "created") {
      setShowCreated((prev) => !prev);
      if (showCreated && !showNotCreated) {
        setShowNotCreated(true);
      }
    } else {
      setShowNotCreated((prev) => !prev);
      if (showNotCreated && !showCreated) {
        setShowCreated(true);
      }
    }
    setMessage(""); // Clear the message when changing filters
  };

  const currentRecipe = filteredRecipes[currentRecipeIndex];

  return (
    <GameWrapper backgroundImage="/Spells.webp">
      <button
        onClick={() => handleButtonClick("/home")}
        className={`${styles.button1} ${styles.button}`}
      >
        Home
      </button>
      <button
        onClick={() => handleButtonClick("/cottage-potions")}
        className={`${styles.button2} ${styles.button}`}
      >
        Items
      </button>
      <button
        onClick={() => handleButtonClick("/cottage-bedroom")}
        className={`${styles.button3} ${styles.button}`}
      >
        Bedroom
      </button>
      <button
        onClick={() => handleButtonClick("/cottage-basement")}
        className={`${styles.button4} ${styles.button}`}
      >
        Basement
      </button>
      <button
        onClick={openModal}
        className={`${styles.button5} ${styles.button}`}
      >
        Open Spell Book
      </button>

      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.successModal}>
            <div className={styles.checkboxContainer}>
              <label className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  checked={showCreated}
                  onChange={() => handleCheckboxChange("created")}
                />
                Show Created Recipes
              </label>
              <label className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  checked={showNotCreated}
                  onChange={() => handleCheckboxChange("notCreated")}
                />
                Show Uncreated Recipes
              </label>
            </div>
            {filteredRecipes.length > 0 && currentRecipe ? (
              <>
                <div className={styles.recipeNumber}>
                  Recipe {currentRecipeIndex + 1} of {filteredRecipes.length}
                </div>
                <h2>Recipe Book</h2>
                <h3 className={styles.recipeNameReveal}>
                  {currentRecipe.name}
                </h3>
                <div className={styles.recipeItems}>
                  {currentRecipe.items.map((item, index) => (
                    <div key={index} className={styles.recipeItem}>
                      <img
                        src={item.imagePath}
                        alt={item.name}
                        className={styles.itemImage}
                      />
                      <p className={styles.itemName}>
                        {item.name} (x{item.amount})
                        {!currentRecipe.created && (
                          <span
                            className={
                              hasEnoughItems(item)
                                ? styles.checkmark
                                : styles.cross
                            }
                          >
                            {hasEnoughItems(item) ? " ✓" : " ✗"}
                          </span>
                        )}
                      </p>
                    </div>
                  ))}
                </div>
                <div className={styles.navigationButtons}>
                  <button onClick={previousRecipe} className={styles.navButton}>
                    Previous
                  </button>
                  <button onClick={nextRecipe} className={styles.navButton}>
                    Next
                  </button>
                </div>
                {currentRecipe.created ? (
                  <button
                    onClick={viewCreatedRecipe}
                    className={`${styles.modalButton} ${styles.viewButton}`}
                  >
                    View Created Recipe
                  </button>
                ) : (
                  <button
                    onClick={handleCreateRecipe}
                    className={`${styles.modalButton} ${styles.createButton}`}
                  >
                    Create Recipe
                  </button>
                )}
              </>
            ) : (
              <p className={styles.noRecipesMessage}>{message}</p>
            )}
            <div className={styles.messageContainer}>
              {message && filteredRecipes.length > 0 && (
                <p className={styles.message}>{message}</p>
              )}
            </div>
            <button
              onClick={closeModal}
              className={`${styles.modalButton} ${styles.okButton}`}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {isSuccessModalOpen && createdRecipe && (
        <div className={styles.modalOverlay}>
          <div className={styles.successModal}>
            <h2>Congratulations!</h2>
            <p>You have successfully created:</p>
            <h3 className={styles.recipeNameReveal}>{createdRecipe.name}</h3>
            <div className={styles.recipeImage}>
              <img
                src={createdRecipe.createdData.imagePath}
                alt={createdRecipe.name}
              />
            </div>
            <p className={styles.recipeDescription}>
              {createdRecipe.createdData.description}
            </p>
            <p className={styles.recipeDescription}>
              {JSON.stringify(createdRecipe.createdData.stats)}
            </p>
            <button
              onClick={() => {
                setIsSuccessModalOpen(false);
                setCreatedRecipe(null);
              }}
              className={`${styles.modalButton} ${styles.okButton}`}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </GameWrapper>
  );
};

export default CottageSpells;
