import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CottageTrapDoor.module.css';
import GameWrapper from './GameWrapper';
const CottageTrapDoor = () => {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <GameWrapper backgroundImage='/TrapDoorRoom.webp'>
      <button
          onClick={() => handleButtonClick('/cottage-basement')}  /* You can assign any path you want here */
          className={`${styles.button5} ${styles.invisibleButton}`}
        >
        </button>
      </GameWrapper>
  );
};

export default CottageTrapDoor;
