import React, { useState, useEffect, useCallback, useRef } from 'react';
import styles from './ShapeGame.module.css';
import { usePlayerState, generateRandomGold, generateRandomItem } from '../../context/PlayerStateContext';
import { mapItems } from '../../constants/itemConstants';
import PrizeModal from '../additional/PrizeModal';

const ShapeSorter = () => {
  const { playerState, setPlayerState, addItem, incrementPuzzleCompletionCount } = usePlayerState();
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60);
  const [gameActive, setGameActive] = useState(false);
  const [currentShape, setCurrentShape] = useState(null);
  const [visible, setVisible] = useState(false);
  const [showPrizeModal, setShowPrizeModal] = useState(false);
  const [prize, setPrize] = useState({ gold: 0, item: null, score: 0 });
  const [isWin, setIsWin] = useState(false);
  const [earnedReward, setEarnedReward] = useState(null);

  const scoreRef = useRef(0);

  const shapes = ['circle', 'square', 'triangle', 'star', 'hexagon', 'diamond', 'pentagon', 'octagon'];
  const colors = ['red', 'blue', 'green', 'yellow', 'purple', 'orange', 'pink', 'teal'];

  const requiredScore = 20; // Set the required score to win

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const earnReward = useCallback(() => {
    const gold = generateRandomGold();
    const mapId = 'noble_lands';
    const mapData = mapItems.find(map => map.id === mapId);
    const item = generateRandomItem(mapData.items);
    return { gold, item };
  }, []);

  const endGame = useCallback(() => {
    setGameActive(false);
    const finalScore = scoreRef.current;
    const isWinner = finalScore >= requiredScore;

    const earnedPrize = earnReward();
    setEarnedReward(earnedPrize);

    setPrize({
      gold: isWinner ? earnedPrize.gold : 0,
      item: isWinner ? earnedPrize.item : null,
      score: finalScore,
      goldImagePath: "/gold.png",
      experience: isWinner ? 0.02 : 0
    });

    setShowPrizeModal(true);
    setIsWin(isWinner);

    if (isWinner) {
      setPlayerState(prevState => ({
        ...prevState,
        gold: prevState.gold + earnedPrize.gold,
        level: prevState.level + 0.02
      }));

      incrementPuzzleCompletionCount('noble_lands', 1);
      
      if (earnedPrize.item) {
        addItem(earnedPrize.item);
      }
    }
  }, [earnReward, incrementPuzzleCompletionCount, addItem, requiredScore, setPlayerState]);

  const startGame = () => {
    setScore(0);
    scoreRef.current = 0;
    setTimeLeft(60);
    setGameActive(true);
    setShowPrizeModal(false);
    setIsWin(false);
    setEarnedReward(null);
    generateShape();

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          endGame();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  };

  const generateShape = () => {
    const shape = shapes[Math.floor(Math.random() * shapes.length)];
    const color = colors[Math.floor(Math.random() * colors.length)];
    setCurrentShape({ shape, color });
  };

  const handleShapeClick = (clickedShape) => {
    if (clickedShape === currentShape.shape) {
      const newScore = scoreRef.current + 1;
      scoreRef.current = newScore;
      setScore(newScore);
    } else {
      const newScore = Math.max(0, scoreRef.current - 1);
      scoreRef.current = newScore;
      setScore(newScore);
    }
    generateShape();
  };

  const renderShape = (shape, color) => {
    return (
      <div 
        className={`${styles.shape} ${styles[shape]}`} 
        style={{ backgroundColor: color, borderColor: color }}
      >
        {shape === 'star' && '★'}
      </div>
    );
  };

  return (
    <div>
      {!showPrizeModal ? (
        <div className={`${styles.gameContainer} ${visible ? styles.visible : ''}`}>
          <h2 className={styles.title}>Shape Sorter</h2>
          <p className={styles.instructions}>
            {gameActive 
              ? "Click the correct shape name that matches the displayed shape!"
              : `Match the shapes to score points! You need ${requiredScore} points to win. Click Start to begin.`}
          </p>
          {!gameActive && (
            <button className={styles.startButton} onClick={startGame}>
              Start Game
            </button>
          )}
          {gameActive && (
            <>
              <div className={styles.gameInfo}>
                Score: {score} | Time Left: {timeLeft}s
              </div>
              <div className={styles.shapeDisplay}>
                {currentShape && renderShape(currentShape.shape, currentShape.color)}
              </div>
              <div className={styles.buttonContainer}>
                {shapes.map((shape) => (
                  <button
                    key={shape}
                    className={styles.shapeButton}
                    onClick={() => handleShapeClick(shape)}
                  >
                    {shape}
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
      ) : (
        <PrizeModal 
          isOpen={showPrizeModal} 
          onClose={startGame} 
          prize={prize}
          isWin={isWin}
          playerGold={playerState.gold}
          earnedReward={earnedReward}
        />
      )}
    </div>
  );
};

export default ShapeSorter;