import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import HomePage from "./components/HomePage";
import MapPage from "./components/MapPage";
import CottagePotions from "./components/CottagePotions";
import CottageSpells from "./components/CottageSpells";
import CottageBedroom from "./components/CottageBedroom";
import CottageStorage from "./components/CottageStorage";
import CottageTrapDoor from "./components/CottageTrapDoor";
import CrystalBall from "./components/CrystalBall";
import PlayerPage from "./components/PlayerPage";
import WishingWell from "./components/WishingWell";
import PuzzleHut from "./components/PuzzleHut";
import CastleFront from "./components/gamePages/CastleFront";
import CastleGreatRoom from "./components/gamePages/CastleGreatRoom";
import CastleTowerRoom from "./components/gamePages/CastleTowerRoom";
import SilverLake from "./components/gamePages/SilverLake";
import KingsGuard from "./components/gamePages/KingsGuard";
import WaterFall from "./components/gamePages/WaterFall";
import WaterChapel from "./components/gamePages/WaterChapel";
import NobleVillage from "./components/gamePages/NobleVillage";
import EnchantedFountain from "./components/gamePages/EnchantedFountain";
import AtlantisRetreat from "./components/gamePages/AtlantisRetreat";
import ImagePreloader from "./utility/ImagePreloaderFunc";
import { PlayerStateProvider } from "./context/PlayerStateContext";
import "./App.css";

const imageUrls = [
  "AtlantisRetreat.webp",
  "Bedroom.webp",
  "CastleFront.webp",
  "CastleGreatRoom.webp",
  "CastleTowerRoom.webp",
  "ChestRoomLocked.webp",
  "ChestRoomUnlocked.webp",
  "Creatures/AncientGranitehornRam.jpg",
  "Creatures/AstralMouselight.jpg",
  "Creatures/AuroraLynx.jpg",
  "Creatures/BasiliskOfThePetrifiedForest.webp",
  "Creatures/BeastOfTwilightEmbrace.webp",
  "Creatures/CelestialFoxlore.webp",
  "Creatures/CelestialLeonix.webp",
  "Creatures/CerberusOfTheUnderworldGates.webp",
  "Creatures/ChimeraOfTheForgottenRealms.webp",
  "Creatures/CrystalwingSerpent.webp",
  "Creatures/FirestormFoxOfEmbers.jpg",
  "Creatures/ForestWarden.jpg",
  "Creatures/FrostwhisperSerpent.webp",
  "Creatures/GeckoOfTheMysticSwamp.jpg",
  "Creatures/GemscaleDragon.jpg",
  "Creatures/GlacialGuardianBearOfTheIcelands.jpg",
  "Creatures/GlimmerwingSprite.webp",
  "Creatures/KrakenOfTheAbyssalDepths.webp",
  "Creatures/LeafspriteSylph.webp",
  "Creatures/LuminaraFlutter.jpg",
  "Creatures/LuminousSerpenta.webp",
  "Creatures/LunarisDraconisOfTheCelestialAbyss.jpg",
  "Creatures/MistveilFawn.webp",
  "Creatures/MoonlitFoxhound.webp",
  "Creatures/MoonshadowDragon.webp",
  "Creatures/MouseOfMoonlight.jpg",
  "Creatures/PegasusOfTheRainbowSkies.webp",
  "Creatures/RadientTurtleOfTheCosmicSeas.jpg",
  "Creatures/SapphireSeaSerpent.jpg",
  "Creatures/SphinxOfTheRiddleRuins.webp",
  "Creatures/StardustFennec.jpg",
  "Creatures/StarryWingedLeonara.webp",
  "Creatures/StarstriderStag.jpg",
  "Creatures/SunlitLeonis.webp",
  "Creatures/TridentHydra.webp",
  "Creatures/TriumphantCerulean.webp",
  "Creatures/VerdantDrakonis.webp",
  "Creatures/WispwingSprite.webp",
  "CrystalBall.png",
  "CrystalBall.webp",
  "EnchantedFountain.webp",
  "GameTemplate.webp",
  "Gemstones/Amethyst.png",
  "Gemstones/Aquamarine.png",
  "Gemstones/Citrine.png",
  "Gemstones/Diamond.png",
  "Gemstones/Emerald.png",
  "Gemstones/Garnet.png",
  "Gemstones/Jade.png",
  "Gemstones/LapisLazuli.png",
  "Gemstones/Moonstone.png",
  "Gemstones/Onyx.png",
  "Gemstones/Opal.png",
  "Gemstones/Peridot.png",
  "Gemstones/Ruby.png",
  "Gemstones/Sapphire.png",
  "Gemstones/Spinel.png",
  "Gemstones/Tanzanite.png",
  "Gemstones/Topaz.png",
  "Gemstones/Tourmaline.png",
  "Gemstones/Turquoise.png",
  "Gemstones/Zircon.png",
  "gold.png",
  "Home.webp",
  "Icons/artifactIcon.webp",
  "Icons/creatureIcon.webp",
  "Icons/gemIcon.webp",
  "Icons/goldIcon.webp",
  "Icons/ingredientIcon.webp",
  "Icons/levelIcon.webp",
  "Icons/mapIcon.webp",
  "Icons/potionIcon.webp",
  "Icons/puzzleIcon.webp",
  "Icons/recipeIcon.webp",
  "Icons/runeIcon.webp",
  "Icons/songIcon.webp",
  "Ingredients/Ancient Leaves.png",
  "Ingredients/Ancient Oak Bark.png",
  "Ingredients/Ants.png",
  "Ingredients/Baby Frogs.png",
  "Ingredients/Bats.png",
  "Ingredients/Blue Ants.png",
  "Ingredients/Blue Eggs.png",
  "Ingredients/Blue Feathers.png",
  "Ingredients/Butterflies.png",
  "Ingredients/Caterpillars.png",
  "Ingredients/Christmas Beetles.png",
  "Ingredients/Cinimon Scrolls.png",
  "Ingredients/Coral.png",
  "Ingredients/Cosmic Planet Dust.png",
  "Ingredients/Crabs.png",
  "Ingredients/Dragon Bones.png",
  "Ingredients/Dragon Scales.png",
  "Ingredients/Elven Leaves.png",
  "Ingredients/Enchanted Acorns.png",
  "Ingredients/Enchanted Apples.png",
  "Ingredients/Figs.png",
  "Ingredients/Finger Limes.png",
  "Ingredients/Fire Flies.png",
  "Ingredients/Four Leaf Clovers.png",
  "Ingredients/Fox Tails.png",
  "Ingredients/Frogs.png",
  "Ingredients/Glowing Chillies.png",
  "Ingredients/Horse Hair.png",
  "Ingredients/Lava.png",
  "Ingredients/Lemons.png",
  "Ingredients/Lightning.png",
  "Ingredients/Lizards.png",
  "Ingredients/Magic Sand.png",
  "Ingredients/Magical Moss.png",
  "Ingredients/Mantis.png",
  "Ingredients/Moon Dust.png",
  "Ingredients/Mushrooms.png",
  "Ingredients/Octopus Legs.png",
  "Ingredients/Orchard Swallow Tail Butterflies.png",
  "Ingredients/Passion Flower.png",
  "Ingredients/Phoniex Feathers.png",
  "Ingredients/Poison Berries.png",
  "Ingredients/Prawns.png",
  "Ingredients/Pumpkins.png",
  "Ingredients/Purple Flowers.png",
  "Ingredients/Rainbow Magic Beans.png",
  "Ingredients/Rainbow Roses.png",
  "Ingredients/Red Lillys.png",
  "Ingredients/Rosemary.png",
  "Ingredients/Spider Web.png",
  "Ingredients/Star Anise.png",
  "Ingredients/Sunflower Seeds.png",
  "Ingredients/Tornado.png",
  "Ingredients/Troll Toe Nails.png",
  "Ingredients/Unicorn Horns.png",
  "Ingredients/Wheat.png",
  "Ingredients/Wolf Teeth.png",
  "Ingredients/Worms.png",
  "Ingredients/Yellow Feathers.png",
  "Ingredients/Yellow Spiders.png",
  "KingsGuard.webp",
  "Landing.webp",
  "Map2.jpg",
  "NobleVillage.webp",
  "Potions/Alpine Essence.png",
  "Potions/Blood moon Elixir.png",
  "Potions/Brew of Agility.png",
  "Potions/Brew of Elemental Resistance.png",
  "Potions/Brew of Shadows.png",
  "Potions/Brew of Teleportation.png",
  "Potions/Celestial Sphere Elixir.png",
  "Potions/Draught of Arcane Sight.png",
  "Potions/Draught of Holy Water.png",
  "Potions/Draught of Lightning.png",
  "Potions/Draught of Stoneskin.png",
  "Potions/Draught of Summoning.png",
  "Potions/Earthbound Elixir.png",
  "Potions/Elixir of Arcane Power.png",
  "Potions/Elixir of Clarity.png",
  "Potions/Elixir of Dragon's Breath.png",
  "Potions/Elixir of Eternal Youth.png",
  "Potions/Elixir of Fortitude.png",
  "Potions/Elixir of Life.png",
  "Potions/Elixir of Moss.png",
  "Potions/Elixir of Perfect Health.png",
  "Potions/Elixir of Vitality.png",
  "Potions/Fairy Dust Elixir.png",
  "Potions/Golden Potion of Might.png",
  "Potions/Mermaid's Whisper Elixir.png",
  "Potions/Morning Potion.png",
  "Potions/Nimbus Drift Potion.png",
  "Potions/Potion of Common.png",
  "Potions/Potion of Deep Sea.png",
  "Potions/Potion of Growth.png",
  "Potions/Potion of Invisibility.png",
  "Potions/Potion of joy.png",
  "Potions/Potion of Mana.png",
  "Potions/Potion of Necromancy.png",
  "Potions/Potion of Noble.png",
  "Potions/Potion of Protection.png",
  "Potions/Potion of Regeneration.png",
  "Potions/Potion of Sage.png",
  "Potions/Potion of.png",
  "Potions/Red Sea Elixir.png",
  "Potions/Seashell Serenity Elixir.png",
  "Potions/Snake Venom.png",
  "Potions/Solar Radiance Elixir.png",
  "Potions/Spider Venom.png",
  "Potions/Tonic of Elemental Fury.png",
  "Potions/Tonic of Inferno.png",
  "Potions/Tonic of Mind Shield.png",
  "Potions/Tonic of Stealth.png",
  "Potions/Tonic of Wisdom.png",
  "Potions/Vine's Bounty Elixir.png",
  "Potions/Void's Enigma Potion.png",
  "Potions.webp",
  "PuzzleHut.webp",
  "Runes/Algiz.png",
  "Runes/Ansuz.png",
  "Runes/Berkana.png",
  "Runes/Dagaz.png",
  "Runes/Ehwaz.png",
  "Runes/Eihwaz.png",
  "Runes/Fehu.png",
  "Runes/Gebo.png",
  "Runes/Hagalaz.png",
  "Runes/Ingwaz.png",
  "Runes/Isa.png",
  "Runes/Jera.png",
  "Runes/Kenaz.png",
  "Runes/Laguz.png",
  "Runes/Mannaz.png",
  "Runes/Naudhiz.png",
  "Runes/Othala.png",
  "Runes/Perthro.png",
  "Runes/Raido.png",
  "Runes/Sowilo.png",
  "Runes/Teiwaz.png",
  "Runes/Thurisaz.png",
  "Runes/Uruz.png",
  "Runes/Wunjo.png",
  "SilverLake.webp",
  "spellBook.png",
  "Spells.webp",
  "TrapDoorRoom.webp",
  "WaterChapel.webp",
  "Waterfall.webp",
  "WishingWell.webp",
  "Witch.webp",
  "Wizard.webp",
  "Creatures/AncientGranitehornRam.jpg",
  "Creatures/AstralMouselight.jpg",
  "Creatures/AuroraLynx.jpg",
  "Creatures/BasiliskOfThePetrifiedForest.webp",
  "Creatures/BeastOfTwilightEmbrace.webp",
  "Creatures/CelestialFoxlore.webp",
  "Creatures/CelestialLeonix.webp",
  "Creatures/CerberusOfTheUnderworldGates.webp",
  "Creatures/ChimeraOfTheForgottenRealms.webp",
  "Creatures/CrystalwingSerpent.webp",
  "Creatures/FirestormFoxOfEmbers.jpg",
  "Creatures/ForestWarden.jpg",
  "Creatures/FrostwhisperSerpent.webp",
  "Creatures/GeckoOfTheMysticSwamp.jpg",
  "Creatures/GemscaleDragon.jpg",
  "Creatures/GlacialGuardianBearOfTheIcelands.jpg",
  "Creatures/GlimmerwingSprite.webp",
  "Creatures/KrakenOfTheAbyssalDepths.webp",
  "Creatures/LeafspriteSylph.webp",
  "Creatures/LuminaraFlutter.jpg",
  "Creatures/LuminousSerpenta.webp",
  "Creatures/LunarisDraconisOfTheCelestialAbyss.jpg",
  "Creatures/MistveilFawn.webp",
  "Creatures/MoonlitFoxhound.webp",
  "Creatures/MoonshadowDragon.webp",
  "Creatures/MouseOfMoonlight.jpg",
  "Creatures/PegasusOfTheRainbowSkies.webp",
  "Creatures/RadientTurtleOfTheCosmicSeas.jpg",
  "Creatures/SapphireSeaSerpent.jpg",
  "Creatures/SphinxOfTheRiddleRuins.webp",
  "Creatures/StardustFennec.jpg",
  "Creatures/StarryWingedLeonara.webp",
  "Creatures/StarstriderStag.jpg",
  "Creatures/SunlitLeonis.webp",
  "Creatures/TridentHydra.webp",
  "Creatures/TriumphantCerulean.webp",
  "Creatures/VerdantDrakonis.webp",
  "Creatures/WispwingSprite.webp",
  "Potions/Alpine Essence.png",
  "Potions/Blood moon Elixir.png",
  "Potions/Brew of Agility.png",
  "Potions/Brew of Elemental Resistance.png",
  "Potions/Brew of Shadows.png",
  "Potions/Brew of Teleportation.png",
  "Potions/Celestial Sphere Elixir.png",
  "Potions/Draught of Arcane Sight.png",
  "Potions/Draught of Holy Water.png",
  "Potions/Draught of Lightning.png",
  "Potions/Draught of Stoneskin.png",
  "Potions/Draught of Summoning.png",
  "Potions/Earthbound Elixir.png",
  "Potions/Elixir of Arcane Power.png",
  "Potions/Elixir of Clarity.png",
  "Potions/Elixir of Dragon's Breath.png",
  "Potions/Elixir of Eternal Youth.png",
  "Potions/Elixir of Fortitude.png",
  "Potions/Elixir of Life.png",
  "Potions/Elixir of Moss.png",
  "Potions/Elixir of Perfect Health.png",
  "Potions/Elixir of Vitality.png",
  "Potions/Fairy Dust Elixir.png",
  "Potions/Golden Potion of Might.png",
  "Potions/Mermaid's Whisper Elixir.png",
  "Potions/Morning Potion.png",
  "Potions/Nimbus Drift Potion.png",
  "Potions/Potion of Common.png",
  "Potions/Potion of Deep Sea.png",
  "Potions/Potion of Growth.png",
  "Potions/Potion of Invisibility.png",
  "Potions/Potion of joy.png",
  "Potions/Potion of Mana.png",
  "Potions/Potion of Necromancy.png",
  "Potions/Potion of Noble.png",
  "Potions/Potion of Protection.png",
  "Potions/Potion of Regeneration.png",
  "Potions/Potion of Sage.png",
  "Potions/Potion of.png",
  "Potions/Red Sea Elixir.png",
  "Potions/Seashell Serenity Elixir.png",
  "Potions/Snake Venom.png",
  "Potions/Solar Radiance Elixir.png",
  "Potions/Spider Venom.png",
  "Potions/Tonic of Elemental Fury.png",
  "Potions/Tonic of Inferno.png",
  "Potions/Tonic of Mind Shield.png",
  "Potions/Tonic of Stealth.png",
  "Potions/Tonic of Wisdom.png",
  "Potions/Vine's Bounty Elixir.png",
  "Potions/Void's Enigma Potion.png",
  "Runes/Algiz.png",
  "Runes/Ansuz.png",
  "Runes/Berkana.png",
  "Runes/Dagaz.png",
  "Runes/Ehwaz.png",
  "Runes/Eihwaz.png",
  "Runes/Fehu.png",
  "Runes/Gebo.png",
  "Runes/Hagalaz.png",
  "Runes/Ingwaz.png",
  "Runes/Isa.png",
  "Runes/Jera.png",
  "Runes/Kenaz.png",
  "Runes/Laguz.png",
  "Runes/Mannaz.png",
  "Runes/Naudhiz.png",
  "Runes/Othala.png",
  "Runes/Perthro.png",
  "Runes/Raido.png",
  "Runes/Sowilo.png",
  "Runes/Teiwaz.png",
  "Runes/Thurisaz.png",
  "Runes/Uruz.png",
  "Runes/Wunjo.png",
  "Ingredients/Ancient Leaves.png",
  "Ingredients/Ancient Oak Bark.png",
  "Ingredients/Ants.png",
  "Ingredients/Baby Frogs.png",
  "Ingredients/Bats.png",
  "Ingredients/Blue Ants.png",
  "Ingredients/Blue Eggs.png",
  "Ingredients/Blue Feathers.png",
  "Ingredients/Butterflies.png",
  "Ingredients/Caterpillars.png",
  "Ingredients/Christmas Beetles.png",
  "Ingredients/Cinimon Scrolls.png",
  "Ingredients/Coral.png",
  "Ingredients/Cosmic Planet Dust.png",
  "Ingredients/Crabs.png",
  "Ingredients/Dragon Bones.png",
  "Ingredients/Dragon Scales.png",
  "Ingredients/Elven Leaves.png",
  "Ingredients/Enchanted Acorns.png",
  "Ingredients/Enchanted Apples.png",
  "Ingredients/Figs.png",
  "Ingredients/Finger Limes.png",
  "Ingredients/Fire Flies.png",
  "Ingredients/Four Leaf Clovers.png",
  "Ingredients/Fox Tails.png",
  "Ingredients/Frogs.png",
  "Ingredients/Glowing Chillies.png",
  "Ingredients/Horse Hair.png",
  "Ingredients/Lava.png",
  "Ingredients/Lemons.png",
  "Ingredients/Lightning.png",
  "Ingredients/Lizards.png",
  "Ingredients/Magic Sand.png",
  "Ingredients/Magical Moss.png",
  "Ingredients/Mantis.png",
  "Ingredients/Moon Dust.png",
  "Ingredients/Mushrooms.png",
  "Ingredients/Octopus Legs.png",
  "Ingredients/Orchard Swallow Tail Butterflies.png",
  "Ingredients/Passion Flower.png",
  "Ingredients/Phoniex Feathers.png",
  "Ingredients/Poison Berries.png",
  "Ingredients/Prawns.png",
  "Ingredients/Pumpkins.png",
  "Ingredients/Purple Flowers.png",
  "Ingredients/Rainbow Magic Beans.png",
  "Ingredients/Rainbow Roses.png",
  "Ingredients/Red Lillys.png",
  "Ingredients/Rosemary.png",
  "Ingredients/Spider Web.png",
  "Ingredients/Star Anise.png",
  "Ingredients/Sunflower Seeds.png",
  "Ingredients/Tornado.png",
  "Ingredients/Troll Toe Nails.png",
  "Ingredients/Unicorn Horns.png",
  "Ingredients/Wheat.png",
  "Ingredients/Wolf Teeth.png",
  "Ingredients/Worms.png",
  "Ingredients/Yellow Feathers.png",
  "Ingredients/Yellow Spiders.png",
  "Icons/artifactIcon.webp",
  "Icons/creatureIcon.webp",
  "Icons/gemIcon.webp",
  "Icons/goldIcon.webp",
  "Icons/ingredientIcon.webp",
  "Icons/levelIcon.webp",
  "Icons/mapIcon.webp",
  "Icons/potionIcon.webp",
  "Icons/puzzleIcon.webp",
  "Icons/recipeIcon.webp",
  "Icons/runeIcon.webp",
  "Icons/songIcon.webp",
  "Gemstones/Amethyst.png",
  "Gemstones/Aquamarine.png",
  "Gemstones/Citrine.png",
  "Gemstones/Diamond.png",
  "Gemstones/Emerald.png",
  "Gemstones/Garnet.png",
  "Gemstones/Jade.png",
  "Gemstones/LapisLazuli.png",
  "Gemstones/Moonstone.png",
  "Gemstones/Onyx.png",
  "Gemstones/Opal.png",
  "Gemstones/Peridot.png",
  "Gemstones/Ruby.png",
  "Gemstones/Sapphire.png",
  "Gemstones/Spinel.png",
  "Gemstones/Tanzanite.png",
  "Gemstones/Topaz.png",
  "Gemstones/Tourmaline.png",
  "Gemstones/Turquoise.png",
  "Gemstones/Zircon.png",
];
function App() {
  return (
    <PlayerStateProvider>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/loading" element={<ImagePreloader imageUrls={imageUrls} />}/>
        <Route path="/home" element={<HomePage />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/cottage-potions" element={<CottagePotions />} />
        <Route path="/cottage-spells" element={<CottageSpells />} />
        <Route path="/cottage-bedroom" element={<CottageBedroom />} />
        <Route path="/cottage-basement" element={<CottageStorage />} />
        <Route path="/cottage-trap-door" element={<CottageTrapDoor />} />
        <Route path="/crystal-ball" element={<CrystalBall />} />
        <Route path="/player" element={<PlayerPage />} />
        <Route path="/wishing-well" element={<WishingWell />} />
        <Route path="/puzzle-hut" element={<PuzzleHut />} />
        <Route path="/castle-front" element={<CastleFront />} />
        <Route path="/castle-great-room" element={<CastleGreatRoom />} />
        <Route path="/castle-tower-room" element={<CastleTowerRoom />} />
        <Route path="/silver-lake" element={<SilverLake />} />
        <Route path="/kings-guard" element={<KingsGuard />} />
        <Route path="/waterfall" element={<WaterFall />} />
        <Route path="/water-chapel" element={<WaterChapel />} />
        <Route path="/noble-village" element={<NobleVillage />} />
        <Route path="/enchanted-fountain" element={<EnchantedFountain />} />
        <Route path="/atlantis-retreat" element={<AtlantisRetreat />} />
      </Routes>
    </PlayerStateProvider>
  );
}

export default App;
