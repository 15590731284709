import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import styles from './StartYourJourneyText.module.css'; // Import the CSS file for styling

const SpringTextAnimation = () => {
  const props = useSpring({
    loop: { reverse: true },
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
    delay : 1000
  });

  return (
    <animated.div style={props} className={styles.springText}>
      Start Your Adventure!
    </animated.div>
  );
};

export default SpringTextAnimation;