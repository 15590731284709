import React from "react";
import { useNavigate } from "react-router-dom";
import { usePlayerState } from "../context/PlayerStateContext";
import styles from "./CottagePotions.module.css";
import PotionGrid from "./additional/StorageGrid";
import GameWrapper from "./GameWrapper";

const CottagePotions = () => {
  const navigate = useNavigate();
  const { playerState } = usePlayerState();

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <GameWrapper backgroundImage="/Potions.webp">
      <button
        onClick={() => handleButtonClick("/home")}
        className={`${styles.button1} ${styles.button}`}
      >
        Home
      </button>
      <button
        onClick={() => handleButtonClick("/cottage-spells")}
        className={`${styles.button2} ${styles.button}`}
      >
        Spells
      </button>
      <button
        onClick={() => handleButtonClick("/cottage-bedroom")}
        className={`${styles.button3} ${styles.button}`}
      >
        Bedroom
      </button>
      <button
        onClick={() => handleButtonClick("/cottage-basement")}
        className={`${styles.button4} ${styles.button}`}
      >
        Basement
      </button>

      <PotionGrid
        potions={playerState.potions}
        ingredients={playerState.ingredients}
        gemstones={playerState.gemstones}
        runes={playerState.runes}
      />
    </GameWrapper>
  );
};

export default CottagePotions;