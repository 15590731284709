import React from 'react';
import PropTypes from 'prop-types';
import styles from './GameWrapper.module.css';

function GameWrapper({ children, backgroundImage }) {
    return (
      <div className={styles.pageWrapper}>
        <div 
          className={styles.pageContainer}
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          {children}
        </div>
      </div>
    );
  }

GameWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundImage: PropTypes.string.isRequired,
};

export default GameWrapper;