// MysticText.jsx
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import styles from './MysticText.module.css';

const MysticText = () => {
  const textRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(textRef.current, 
      { opacity: 0, scale: 0.3 }, 
      { opacity: 1, scale: 1.2, duration: 2, ease: 'power3.out' }
    );
  }, []);

  return (
    <div className={styles.mysticTextContainer}>
      <h1 ref={textRef} className={styles.mysticText}>The Forest of Magic</h1>
    </div>
  );
};

export default MysticText;