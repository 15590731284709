import React, { useState, useEffect, useCallback } from 'react';
import styles from './RomanNumerals.module.css';
import { usePlayerState, generateRandomGold, generateRandomItem } from '../../context/PlayerStateContext';
import { mapItems } from '../../constants/itemConstants';
import PrizeModal from '../additional/PrizeModal';

const romanNumerals = {
  1: 'I', 2: 'II', 3: 'III', 4: 'IV', 5: 'V',
  6: 'VI', 7: 'VII', 8: 'VIII', 9: 'IX', 10: 'X',
  11: 'XI', 12: 'XII', 13: 'XIII', 14: 'XIV', 15: 'XV',
  16: 'XVI', 17: 'XVII', 18: 'XVIII', 19: 'XIX', 20: 'XX',
  50: 'L', 100: 'C', 150: 'CL', 200: 'CC', 250: 'CCL',
  300: 'CCC', 350: 'CCCL', 400: 'CD', 450: 'CDL', 500: 'D',
  550: 'DL', 600: 'DC', 650: 'DCL', 700: 'DCC', 750: 'DCCL',
  800: 'DCCC', 850: 'DCCCL', 900: 'CM', 950: 'CML', 1000: 'M'
};

const RomanNumeralGame = () => {
  const { playerState, setPlayerState, addItem, incrementPuzzleCompletionCount } = usePlayerState();
  const [currentRoman, setCurrentRoman] = useState('');
  const [userInput, setUserInput] = useState('');
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60);
  const [gameActive, setGameActive] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showPrizeModal, setShowPrizeModal] = useState(false);
  const [prize, setPrize] = useState({ gold: 0, item: null, score: 0 });
  const [isWin, setIsWin] = useState(false);

  const requiredScore = 5; // Set the required score to 5

  const generateNewRoman = useCallback(() => {
    const numbers = Object.keys(romanNumerals);
    const randomNumber = numbers[Math.floor(Math.random() * numbers.length)];
    setCurrentRoman(romanNumerals[randomNumber]);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const earnReward = useCallback(() => {
    const gold = generateRandomGold();
    const mapId = 'noble_lands';
    const mapData = mapItems.find(map => map.id === mapId);
    const item = generateRandomItem(mapData.items);
    return { gold, item };
  }, []);

  const endGame = useCallback(() => {
    setGameActive(false);
    const earnedPrize = earnReward();
    const isWinner = score >= requiredScore;
    
    setPrize({
      gold: isWinner ? earnedPrize.gold : 0,
      item: isWinner ? earnedPrize.item : null,
      score: score,
      goldImagePath: "/gold.png",
      experience: isWinner ? 0.02 : 0
    });

    setShowPrizeModal(true);
    setIsWin(isWinner);

    if (isWinner) {
      setPlayerState(prevState => ({
        ...prevState,
        gold: prevState.gold + earnedPrize.gold,
        level: prevState.level + 0.02,
      }));

      incrementPuzzleCompletionCount('noble_lands', 7); // Assuming this is the 8th puzzle (index 7)
      addItem(earnedPrize.item);
    }
  }, [score, requiredScore, earnReward, setPlayerState, addItem, incrementPuzzleCompletionCount]);

  const startGame = useCallback(() => {
    setScore(0);
    setTimeLeft(60);
    setGameActive(true);
    setShowPrizeModal(false);
    generateNewRoman();
  }, [generateNewRoman]);

  useEffect(() => {
    let timer;
    if (gameActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0 && gameActive) {
      endGame();
    }
    return () => clearInterval(timer);
  }, [timeLeft, gameActive, endGame]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (!gameActive) return;

    const correctAnswer = Object.keys(romanNumerals).find(key => romanNumerals[key] === currentRoman);
    
    if (parseInt(userInput) === parseInt(correctAnswer)) {
      setScore(prevScore => prevScore + 1);
    } else {
      setScore(prevScore => Math.max(0, prevScore - 1));
    }

    setUserInput('');
    generateNewRoman();
  }, [gameActive, currentRoman, userInput, generateNewRoman]);

  return (
    <div>
      {!showPrizeModal ? (
        <div className={`${styles.gameContainer} ${visible ? styles.visible : ''}`}>
          <h2 className={styles.title}>Roman Numeral Game</h2>
          <div className={styles.gameContent}>
            <div className={styles.gameArea}>
              <p className={styles.instructions}>
                Convert the Roman numeral to a number. 
                Score {requiredScore} points in 60 seconds to win!
              </p>
              <div className={styles.romanDisplay}>{currentRoman}</div>
              <form onSubmit={handleSubmit}>
                <input
                  type="number"
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  className={styles.input}
                  min="1"
                  max="1000"
                  required
                />
                <button type="submit" className={styles.submitButton}>Submit</button>
              </form>
              <div className={styles.gameInfo}>
                Score: {score} | Time Left: {timeLeft}s
              </div>
            </div>
            <div className={styles.chart}>
              <h3>Roman Numeral Chart</h3>
              <div className={styles.chartContent}>
                {Object.entries(romanNumerals).map(([number, roman]) => (
                  <div key={number} className={styles.chartItem}>
                    {number}: {roman}
                  </div>
                ))}
              </div>
            </div>
          </div>
          {!gameActive && (
            <button className={styles.startButton} onClick={startGame}>
              {timeLeft === 60 ? "Start Game" : "Play Again"}
            </button>
          )}
        </div>
      ) : (
        <PrizeModal 
          isOpen={showPrizeModal} 
          onClose={startGame} 
          prize={prize}
          isWin={isWin}
        />
      )}
    </div>
  );
};

export default RomanNumeralGame;