import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CastleFront.module.css";
import GameTemplate from "../games/GameTemplate";
import CountingItems from "../games/CountingItems";
import GameWrapper from "../GameWrapper";
const CastleFront = () => {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <GameWrapper backgroundImage="/CastleFront.webp">
      <button
        onClick={() => handleButtonClick("/home")}
        className={`${styles.homeButton} ${styles.button}`}
      >
        Home
      </button>

      <button
        onClick={() => handleButtonClick("/map")}
        className={`${styles.mapButton} ${styles.button}`}
      >
        Map
      </button>

      <CountingItems />
    </GameWrapper>
  );
};

export default CastleFront;
