import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CottageBedroom.module.css';
import GameWrapper from './GameWrapper';
const CottageBedroom = () => {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <GameWrapper backgroundImage='/Bedroom.webp'>
      <button
          onClick={() => handleButtonClick('/home')}  /* You can assign any path you want here */
          className={`${styles.button1} ${styles.button}`}
        >
          Home
        </button>
        <button
          onClick={() => handleButtonClick('/cottage-spells')}  /* You can assign any path you want here */
          className={`${styles.button2} ${styles.button}`}
        >
          Spells
        </button>
        <button
          onClick={() => handleButtonClick('/cottage-potions')}  /* You can assign any path you want here */
          className={`${styles.button3} ${styles.button}`}
        >
          
          Items
        </button>
        <button
          onClick={() => handleButtonClick('/cottage-basement')}  /* You can assign any path you want here */
          className={`${styles.button4} ${styles.button}`}
        >
          
          Basement
        </button>

        <button
          onClick={() => handleButtonClick('/crystal-ball')}  /* You can assign any path you want here */
          className={`${styles.imageButton}`}
        >
          
          
        </button>
     </GameWrapper>
  );
};

export default CottageBedroom;
