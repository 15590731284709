import React from 'react';
import styles from './PuzzleGraph.module.css';

const PuzzleGraph = ({ puzzleData }) => {
  const maxCount = Math.max(...puzzleData.map(puzzle => puzzle.count));
  const benchmarks = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]; // Add more as needed
  const benchmarkColors = ['#FF9999', '#99FF99', '#9999FF', '#FFFF99', '#FF99FF']; // Add more colors as needed
  
  // Calculate the next level threshold
  const nextLevelThreshold = Math.ceil(Math.max(...puzzleData.map(puzzle => puzzle.count)) / 10) * 10 === 0 ? 10 : Math.ceil(Math.max(...puzzleData.map(puzzle => puzzle.count)) / 10) * 10

  return (
    <div className={styles.graphWrapper}>
      <h2 className={styles.graphTitle}>Puzzle Completions</h2>
      <p className={styles.levelInfo}>
        Next level at {nextLevelThreshold} for each puzzle
      </p>
      <div className={styles.graphContainer}>
        <div className={styles.benchmarkLabels}>
          {benchmarks.map((benchmark, index) => (
            <div 
              key={benchmark} 
              className={styles.benchmarkLabel} 
              style={{ 
                bottom: `${(benchmark / maxCount) * 100}%`,
                backgroundColor: benchmarkColors[index % benchmarkColors.length]
              }}
            >
              BONUS 
            </div>
          ))}
        </div>
        <div className={styles.graph}>
          {benchmarks.map((benchmark, index) => (
            <div 
              key={benchmark} 
              className={styles.benchmark} 
              style={{ 
                bottom: `${(benchmark / maxCount) * 100}%`,
                borderColor: benchmarkColors[index % benchmarkColors.length]
              }}
            />
          ))}
          {puzzleData.map((puzzle, index) => (
            <div key={index} className={styles.bar}>
              <div 
                className={styles.barFill} 
                style={{ height: `${(puzzle.count / maxCount) * 100}%` }}
              >
                <span className={styles.barLabel}>{puzzle.count}</span>
              </div>
              <span className={styles.puzzleName}>{puzzle.name}</span>
            </div>
          ))}
          {/* Vertical grid lines */}
          {puzzleData.map((_, index) => (
            <div key={index} className={styles.verticalLine} style={{left: `${(index + 0.5) * (100 / puzzleData.length)}%`}} />
          ))}
          {/* Horizontal grid lines */}
          {[...Array(5)].map((_, index) => (
            <div key={index} className={styles.horizontalLine} style={{bottom: `${(index + 1) * 20}%`}} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PuzzleGraph;
