import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePlayerState } from "../context/PlayerStateContext";
import styles from "./PlayerPage.module.css";
import GameWrapper from "./GameWrapper";
import RewardModal from "../components/additional/RewardModal";

const LEFT_BG_IMAGE = "/Icons/goldIcon.webp";
const RIGHT_BG_IMAGE = "/Icons/levelIcon.webp";

const PlayerPage = () => {
  const navigate = useNavigate();
  const { playerState, checkAndAddLevelReward, checkAndAddPuzzleLevelReward } = usePlayerState();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [isRewardModalOpen, setIsRewardModalOpen] = useState(false);
  const [rewardType, setRewardType] = useState(null);

  const goHome = () => {
    navigate("/home");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldAnimate(true);
    }, 100); // Short delay to ensure the component has rendered

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Check for new level rewards
    checkAndAddLevelReward(playerState.level - 1, playerState.level);
    checkAndAddPuzzleLevelReward(playerState.puzzleLevel - 1, playerState.puzzleLevel);
  }, [playerState.level, playerState.puzzleLevel, checkAndAddLevelReward, checkAndAddPuzzleLevelReward]);

  const handleClaimReward = () => {
    if (playerState.playerLevelRewards.some(reward => !reward.rewardClaimed)) {
      setRewardType('player');
      setIsRewardModalOpen(true);
    } else if (playerState.puzzleLevelRewards.some(reward => !reward.rewardClaimed)) {
      setRewardType('puzzle');
      setIsRewardModalOpen(true);
    }
  };

  const hasUnclaimedRewards = 
    playerState.playerLevelRewards.some(reward => !reward.rewardClaimed) ||
    playerState.puzzleLevelRewards.some(reward => !reward.rewardClaimed);

  const items = [
    {
      name: "Potions",
      count: playerState.potions.reduce((sum, item) => sum + (item.amount || 0), 0),
      imagePath: "/Icons/potionIcon.webp",
    },
    {
      name: "Ingredients",
      count: playerState.ingredients.reduce((sum, item) => sum + (item.amount || 0), 0),
      imagePath: "/Icons/ingredientIcon.webp",
    },
    {
      name: "Runes",
      count: playerState.runes.reduce((sum, item) => sum + (item.amount || 0), 0),
      imagePath: "/Icons/runeIcon.webp",
    },
    {
      name: "Gemstones",
      count: playerState.gemstones.reduce((sum, item) => sum + (item.amount || 0), 0),
      imagePath: "/Icons/gemIcon.webp",
    },
    {
      name: "Recipes",
      count: playerState.recipes.length,
      imagePath: "/Icons/recipeIcon.webp",
    },
    {
      name: "Artifacts",
      count: playerState.artifacts.length,
      imagePath: "/Icons/artifactIcon.webp",
    },
    {
      name: "Maps",
      count: playerState.maps.length,
      imagePath: "/Icons/mapIcon.webp",
    },
    {
      name: "Puzzles",
      count: playerState.puzzlesCompleted,
      imagePath: "/Icons/puzzleIcon.webp",
    },
    {
      name: "Creatures",
      count: playerState.recipes.filter(recipe => recipe.created === true).length,
      imagePath: "/Icons/creatureIcon.webp",
    },
    { name: "Songs", count: 0, imagePath: "/Icons/songIcon.webp" },
  ];

  const leftItems = items.slice(0, 5);
  const rightItems = items.slice(5);

  const currentLevel = playerState.level.toFixed(2);
  const progress = playerState.level % 1;
  const circumference = 2 * Math.PI * 45; // 45 is the radius of the circle

  // Determine the background image based on the player's gender
  const backgroundImage = playerState.gender === "Witch" ? "/Witch.webp" : "/Wizard.webp";

  return (
    <GameWrapper backgroundImage={backgroundImage}>
      <button onClick={goHome} className={styles.homeButton}>
        Home
      </button>

      {hasUnclaimedRewards && (
        <button onClick={handleClaimReward} className={styles.claimRewardButton}>
          Claim Award
        </button>
      )}

      <div 
        className={styles.leftInfo} 
        style={{ backgroundImage: `url(${LEFT_BG_IMAGE})` }}
      >
        <div className={styles.playerInfoContainer}>
          <h2>{playerState.playerName}</h2>
          <h3>Gold: {playerState.gold}</h3>
        </div>
      </div>

      <div 
        className={styles.rightInfo}
        style={{ backgroundImage: `url(${RIGHT_BG_IMAGE})` }}
      >
        <div className={styles.levelContainer}>
          <svg className={styles.progressRing} viewBox="0 0 120 120">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#3e40c5" />
                <stop offset="100%" stopColor="#6e6ff2" />
              </linearGradient>
            </defs>
            <circle
              className={styles.progressRingCircle}
              stroke="#34105a"
              strokeWidth="8"
              fill="transparent"
              r="45"
              cx="60"
              cy="60"
            />
            <circle
              className={`${styles.progressRingCircle} ${
                shouldAnimate ? styles.animate : ""
              }`}
              stroke="url(#gradient)"
              strokeWidth="8"
              fill="transparent"
              r="45"
              cx="60"
              cy="60"
              strokeDasharray={circumference}
              style={{
                "--circumference": `${circumference}px`,
                "--stroke-dashoffset": `${
                  circumference - progress * circumference
                }px`,
              }}
            />
          </svg>
          <div className={styles.levelText}>{currentLevel}</div>
        </div>
      </div>

      <div className={styles.bottomNav}>
        <div className={styles.leftItems}>
          {leftItems.map((item, index) => (
            <div key={index} className={styles.itemContainer}>
              <div
                className={styles.itemImage}
                style={{ backgroundImage: `url(${item.imagePath})` }}
              ></div>
              <div className={styles.itemInfoContainer}>
                <div className={styles.itemName}>{item.name}</div>
                <div className={styles.itemCount}>{item.count}</div>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.rightItems}>
          {rightItems.map((item, index) => (
            <div key={index} className={styles.itemContainer}>
              <div
                className={styles.itemImage}
                style={{ backgroundImage: `url(${item.imagePath})` }}
              ></div>
              <div className={styles.itemInfoContainer}>
                <div className={styles.itemName}>{item.name}</div>
                <div className={styles.itemCount}>{item.count}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <RewardModal 
        isOpen={isRewardModalOpen} 
        onClose={() => setIsRewardModalOpen(false)}
        rewardType={rewardType}
      />
    </GameWrapper>
  );
};

export default PlayerPage;
