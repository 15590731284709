import React, { useState, useEffect } from 'react';
import styles from './StorageGrid.module.css';

const StorageGrid = ({ potions, ingredients, gemstones, runes }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const tabs = ['Potions', 'Ingredients', 'Gemstones', 'Runes', 'All Items'];
  const datasets = [potions, ingredients, gemstones, runes];

  useEffect(() => {
    const timer = setTimeout(() =>  {
      setIsVisible(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const renderItems = (items) => {
    return items.map((item, index) => (
      <div key={index} className={styles.gridItem}>
        <div className={styles.imageContainer}>
          <img src={item.imagePath} alt={item.name} className={styles.itemImage} />
        </div>
        <div className={styles.itemName}>
          {item.name} (x{item.amount})
        </div>
      </div>
    ));
  };

  const getAllItems = () => {
    return [
      ...potions.map(item => ({ ...item, category: 'Potions' })),
      ...ingredients.map(item => ({ ...item, category: 'Ingredients' })),
      ...gemstones.map(item => ({ ...item, category: 'Gemstones' })),
      ...runes.map(item => ({ ...item, category: 'Runes' }))
    ];
  };

  const renderAllItems = () => {
    const allItems = getAllItems();
    return allItems.map((item, index) => (
      <div key={index} className={styles.gridItem}>
        <div className={styles.imageContainer}>
          <img src={item.imagePath} alt={item.name} className={styles.itemImage} />
        </div>
        <div className={styles.itemNameAll}>
          {item.name} (x{item.amount})
        </div>
      </div>
    ));
  };

  return (
    <div className={`${styles.gridWrapper} ${isVisible ? styles.visible : ''}`}>
      <div className={styles.tabContainer}>
        {tabs.map((tab, index) => (
          <button 
            key={index} 
            className={`${styles.tab} ${activeTab === index ? styles.activeTab : ''}`}
            onClick={() => setActiveTab(index)}
          >
            {tab}
          </button>
        ))}
      </div>
      <div className={styles.gridContainer}>
        {activeTab === 4 ? renderAllItems() : renderItems(datasets[activeTab])}
      </div>
    </div>
  );
};

export default StorageGrid;