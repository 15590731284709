import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './CountingItems.module.css';
import { usePlayerState, generateRandomGold, generateRandomItem } from '../../context/PlayerStateContext';
import { mapItems } from '../../constants/itemConstants';
import PrizeModal from '../additional/PrizeModal';

const CountingPuzzle = () => {
  const { playerState, setPlayerState, addItem, incrementPuzzleCompletionCount } = usePlayerState();
  const [itemCount, setItemCount] = useState(0);
  const [userGuess, setUserGuess] = useState('');
  const [gameActive, setGameActive] = useState(false);
  const [visible, setVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState(60);
  const [showPrizeModal, setShowPrizeModal] = useState(false);
  const [prize, setPrize] = useState({ gold: 0, item: null, score: 0 });
  const [isWin, setIsWin] = useState(false);
  const canvasRef = useRef(null);
  const itemsRef = useRef([]);
  const animationRef = useRef(null);
  const gameEndedRef = useRef(false);
  const timerRef = useRef(null);

  const requiredScore = 1; // Correct guess required to win

  const initializeCanvas = useCallback(() => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const count = Math.floor(Math.random() * 18) + 14; // 12 to 24 items
    setItemCount(count);

    // Reduced speed factor
    const speedFactor = 0.4; // Adjust this value to make balls move even slower or faster

    itemsRef.current = Array.from({ length: count }, () => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      dx: (Math.random() - 0.5) * 2 * speedFactor, // Reduced speed
      dy: (Math.random() - 0.5) * 2 * speedFactor, // Reduced speed
      radius: 10,
    }));

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      itemsRef.current.forEach(item => {
        item.x += item.dx;
        item.y += item.dy;

        if (item.x < item.radius || item.x > canvas.width - item.radius) item.dx *= -1;
        if (item.y < item.radius || item.y > canvas.height - item.radius) item.dy *= -1;

        ctx.beginPath();
        ctx.arc(item.x, item.y, item.radius, 0, Math.PI * 2);
        ctx.fillStyle = '#fff';
        ctx.fill();
        ctx.closePath();
      });

      animationRef.current = requestAnimationFrame(animate);
    };

    animate();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 1000);

    return () => {
      clearTimeout(timer);
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (canvasRef.current && gameActive) {
      initializeCanvas();
    }
  }, [gameActive, initializeCanvas]);

  const startGame = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    gameEndedRef.current = false;
    setVisible(true);
    setIsWin(false);
    setUserGuess('');
    setTimeLeft(60);
    setGameActive(true);
    setShowPrizeModal(false);

    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          endGame();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  }, []);

  const earnReward = useCallback(() => {
    const gold = generateRandomGold();
    const mapId = 'noble_lands';
    const mapData = mapItems.find(map => map.id === mapId);
    const item = generateRandomItem(mapData.items);
    return { gold, item };
  }, []);

  const endGame = useCallback(() => {
    if (gameEndedRef.current) return;
    gameEndedRef.current = true;
    setVisible(false);
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    setGameActive(false);
     
    const isCorrect = parseInt(userGuess) === itemCount;
    const currentScore = isCorrect ? 1 : 0;
    
    console.log('Final Score:', currentScore);
    const earnedPrize = earnReward();
    console.log('Earned prize:', earnedPrize);
    setPrize({
      gold: currentScore >= requiredScore ? 3 : 0,
      item: currentScore >= requiredScore ? earnedPrize.item : null,
      score: currentScore,
      goldImagePath: "/gold.png",
      experience: currentScore >= requiredScore ? 0.01 : 0
    });

    setShowPrizeModal(true);

    if (currentScore >= requiredScore) {
      setIsWin(true);
      setPlayerState(prevState => ({
        ...prevState,
        gold: prevState.gold + 3,
        level: prevState.level + 0.01,
      }));

      incrementPuzzleCompletionCount('noble_lands', 5); // Assuming this is the 6th puzzle (index 5)
      addItem(earnedPrize.item);
    }
  }, [earnReward, setPlayerState, addItem, incrementPuzzleCompletionCount, userGuess, itemCount, requiredScore]);

  const handleSubmit = (e) => {
    e.preventDefault();
    endGame();
  };

  return (
    <div>
      {!showPrizeModal ? (
        <div className={`${styles.puzzleContainer} ${visible ? styles.visible : ''}`}>
          <h2 className={styles.title}>Counting Puzzle</h2>
          <p className={styles.instructions}>
            Count the number of moving objects on the screen and enter your guess below.
            You have 60 seconds to submit your answer!
          </p>
          <div className={styles.timer}>Time left: {timeLeft} seconds</div>
          <canvas ref={canvasRef} width={400} height={400} className={styles.canvas}></canvas>
          {!gameActive && (
            <button className={`${styles.button} ${styles.startButton}`} onClick={startGame}>
              Start Game
            </button>
          )}
          {gameActive && (
            <form onSubmit={handleSubmit} className={styles.form}>
              <input
                type="number"
                value={userGuess}
                onChange={(e) => setUserGuess(e.target.value)}
                className={styles.input}
                required
              />
              <button type="submit" className={styles.button}>Submit Guess</button>
            </form>
          )}
        </div>
      ) : (
        <PrizeModal 
          isOpen={showPrizeModal} 
          onClose={startGame} 
          prize={prize}
          isWin={isWin}
        />
      )}
    </div>
  );
};

export default CountingPuzzle;