import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CrystalBall.module.css';
import GameWrapper from './GameWrapper';
import { usePlayerState, getNewRecipe } from '../context/PlayerStateContext';
import { mapItems } from '../constants/itemConstants';

const CrystalBall = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newRecipe, setNewRecipe] = useState(null);
  const { playerState, setPlayerState } = usePlayerState();

  const handleButtonClick = (path) => {
    navigate(path);
  };

  const openModal = () => {
    setIsModalOpen(true);
    setErrorMessage('');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setErrorMessage('');
  };

  const handlePayGold = () => {
    if (playerState.gold >= 100) {
      const recipe = getNewRecipe(mapItems, playerState.recipes);
      setPlayerState(prevState => ({
        ...prevState,
        gold: prevState.gold - 100,
        recipes: [...prevState.recipes, recipe]
      }));
      closeModal();
      setNewRecipe(recipe);
      setIsSuccessModalOpen(true);
    } else {
      setErrorMessage("Not enough gold!");
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setNewRecipe(null);
  };

  return (
    <GameWrapper backgroundImage='/CrystalBall.webp'>
      <button
        onClick={() => handleButtonClick('/cottage-bedroom')}
        className={`${styles.button1} ${styles.button}`}
      >
        Back
      </button>
      <button
        onClick={openModal}
        className={`${styles.button2}`}
      >
      
      </button>
      
      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <h2>Would you like to find a new recipe?</h2>
            <p className={styles.goldInfo}>Your current gold: {playerState.gold}</p>
            <div className={styles.errorMessageContainer}>
              {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
            </div>
            <div className={styles.modalButtons}>
              <button
                onClick={handlePayGold}
                className={`${styles.modalButton} ${styles.payButton}`}
              >
                Pay 100 Gold
              </button>
              <button
                onClick={closeModal}
                className={`${styles.modalButton} ${styles.noButton}`}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {isSuccessModalOpen && newRecipe && (
        <div className={styles.modalOverlay}>
          <div className={styles.successModal}>
            <h2>Congratulations!</h2>
            <p>You have successfully found a new recipe:</p>
            <h3 className={styles.recipeNameReveal}>{newRecipe.name}</h3>
            <div className={styles.recipeItems}>
              {newRecipe.items.map((item, index) => (
                <div key={index} className={styles.recipeItem}>
                  <img src={item.imagePath} alt={item.name} className={styles.itemImage} />
                  <p className={styles.itemName}>{item.name} (x{item.amount})</p>
                </div>
              ))}
            </div>
            <button
              onClick={closeSuccessModal}
              className={`${styles.modalButton} ${styles.okButton}`}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </GameWrapper>
  );
};

export default CrystalBall;