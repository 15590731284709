import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { mapPuzzles } from '../constants/puzzlesConstants';
import { mapItems, creatures} from '../constants/itemConstants';

const PlayerStateContext = createContext();

function getNewRecipe(mapItems, recipes) {
  function getUniqueName() {
    let randomName;
    do {
      const randomIndex = Math.floor(Math.random() * creatures.length);
      randomName = creatures[randomIndex];
    } while (recipes.some(recipe => recipe.name === randomName.name));
    return randomName;
  }

  function getRandomAmount() {
    return Math.floor(Math.random() * 3) + 1; // Random number between 1 and 3
  }

  const uniqueName = getUniqueName();
  const newItems = [];
  const itemCategories = mapItems[0].items;
  
  for (const category in itemCategories) {
    const categoryItems = itemCategories[category];
    const randomItem = categoryItems[Math.floor(Math.random() * categoryItems.length)];
    newItems.push({
      ...randomItem,
      amount: getRandomAmount(),
      category
    });
  }
  
  return {
    name: uniqueName.name,
    imagePath: uniqueName.imagePath,
    description: uniqueName.description,
    stats: uniqueName.stats,
    items: newItems,
    created: false,
    createdData: null
  };
}

const addItemToInventory = (prevState, newItem) => {
  const category = newItem.category;
  const existingItemIndex = prevState[category].findIndex(item => item.name === newItem.name);

  if (existingItemIndex !== -1) {
    // Item already exists, increment the amount
    const updatedCategory = [...prevState[category]];
    updatedCategory[existingItemIndex] = {
      ...updatedCategory[existingItemIndex],
      amount: (updatedCategory[existingItemIndex].amount || 0) + (newItem.amount || 1)
    };
    return {
      ...prevState,
      [category]: updatedCategory
    };
  } else {
    // Item doesn't exist, add it to the category
    return {
      ...prevState,
      [category]: [...prevState[category], { ...newItem, amount: newItem.amount || 1 }]
    };
  }
};

const getInitialMapPuzzles = () => {
  return Object.keys(mapPuzzles).reduce((acc, mapId) => {
    acc[mapId] = {
      ...mapPuzzles[mapId],
      puzzles: mapPuzzles[mapId].puzzles.map(puzzle => ({
        ...puzzle,
        completedCount: 0
      }))
    };
    return acc;
  }, {});
};

const initialState = {
  playerName: '',
  gender: '',
  potions: [],
  ingredients: [],
  runes: [],
  gemstones: [],
  recipes: [getNewRecipe(mapItems, [])], // Add an initial recipe
  artifacts: [],
  gold: 0,
  level: 0,
  puzzlesCompleted: 0,
  puzzleLevel : 0,
  maps: [{name : "noble_lands"}],
  mapPuzzles: getInitialMapPuzzles(),
  playerLevelRewards: [{level : 0, rewardClaimed : true}],
  puzzleLevelRewards: [{level : 0, rewardClaimed : true}]
};

export const generateRandomGold = () => {
  return Math.floor(Math.random() * (10 - 3 + 1)) + 3; // Random number between 3 and 10
};

export const generateRandomItem = (itemsData) => {
  const categories = Object.keys(itemsData);
  const category = categories[Math.floor(Math.random() * categories.length)];
  const items = itemsData[category];
  const item = items[Math.floor(Math.random() * items.length)];
  return {
    category,
    name: item.name,
    imagePath: item.imagePath,
    amount: 1
  };
};

export const PlayerStateProvider = ({ children }) => {
  const [playerState, setPlayerState] = useState(() => {
    const savedState = localStorage.getItem('playerState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      // Ensure there's at least one recipe
      if (parsedState.recipes.length === 0) {
        parsedState.recipes.push(getNewRecipe(mapItems, []));
      }
      // Ensure mapPuzzles is included in the saved state
      if (!parsedState.mapPuzzles) {
        parsedState.mapPuzzles = getInitialMapPuzzles();
      }
      if (!parsedState.playerLevelRewards) {
        parsedState.playerLevelRewards = [];
      }
      if (!parsedState.puzzleLevelRewards) {
        parsedState.puzzleLevelRewards = [];
      }
      return parsedState;
    }
    return JSON.parse(JSON.stringify(initialState));
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('playerState', JSON.stringify(playerState));
  }, [playerState]);

  useEffect(() => {
    const publicRoutes = ['/', '/home']; // Add any other public routes here
    if (!publicRoutes.includes(location.pathname) && (!playerState.playerName || !playerState.gender)) {
      navigate('/home');
    }
  }, [playerState, location, navigate]);

  const resetPlayerState = () => {
    const resetState = {
      ...JSON.parse(JSON.stringify(initialState)),
      mapPuzzles: getInitialMapPuzzles()
    };
    setPlayerState(resetState);
    localStorage.removeItem('playerState');
  };

  const addItem = (newItem) => {
    setPlayerState(prevState => addItemToInventory(prevState, newItem));
  };

  const incrementPuzzleCompletionCount = (mapId, puzzleId) => {
    setPlayerState(prevState => {
      const updatedMapPuzzles = JSON.parse(JSON.stringify(prevState.mapPuzzles));
      if (updatedMapPuzzles[mapId] && updatedMapPuzzles[mapId].puzzles[puzzleId]) {
        updatedMapPuzzles[mapId].puzzles[puzzleId].completedCount++;
      }
      return {
        ...prevState,
        mapPuzzles: updatedMapPuzzles,
        puzzlesCompleted: prevState.puzzlesCompleted + 1
      };
    });
  };

  const getPuzzleCompletionCount = (mapId, puzzleId) => {
    if (playerState.mapPuzzles[mapId] && playerState.mapPuzzles[mapId].puzzles[puzzleId]) {
      return playerState.mapPuzzles[mapId].puzzles[puzzleId].completedCount;
    }
    return 0;
  };

  const checkAndAddLevelReward = (prevLevel, newLevel) => {
    if (playerState.puzzlesCompleted === 0) return; // Ensur
    const wholeNumbersCrossed = Math.floor(newLevel) - Math.floor(prevLevel);
    if (wholeNumbersCrossed > 0) {
      for (let i = 1; i <= wholeNumbersCrossed; i++) {
        const rewardLevel = Math.floor(prevLevel) + i;
        if (!playerState.playerLevelRewards.some(reward => reward.level === rewardLevel)) {
          setPlayerState(prev => ({
            ...prev,
            playerLevelRewards: [...prev.playerLevelRewards, { level: rewardLevel, rewardClaimed: false }]
          }));
        }
      }
    }
  };

  const checkAndAddPuzzleLevelReward = (prevLevel, newLevel) => {
      if (playerState.puzzlesCompleted === 0) return; // Ensur
    const wholeNumbersCrossed = Math.floor(newLevel) - Math.floor(prevLevel);
    if (wholeNumbersCrossed > 0) {
      for (let i = 1; i <= wholeNumbersCrossed; i++) {
        const rewardLevel = Math.floor(prevLevel) + i;
        if (!playerState.puzzleLevelRewards.some(reward => reward.level === rewardLevel)) {
          setPlayerState(prev => ({
            ...prev,
            puzzleLevelRewards: [...prev.puzzleLevelRewards, { level: rewardLevel, rewardClaimed: false }]
          }));
        }
      }
    }
  };
//TODO: need to make sure that claiming each award is happening one at a time
  const claimReward = (rewardType) => {
    setPlayerState(prev => {
      const rewards = rewardType === 'player' ? prev.playerLevelRewards : prev.puzzleLevelRewards;
      const unclaimedReward = rewards.find(reward => !reward.rewardClaimed);
      
      if (unclaimedReward) {
        // Here you can add the logic for what the reward actually gives the player
        const updatedRewards = rewards.map(reward => 
          reward.level === unclaimedReward.level ? {...reward, rewardClaimed: true} : reward
        );
        
        return {
          ...prev,
          gold: prev.gold + 100, // Example: give 100 gold for each level
          [rewardType === 'player' ? 'playerLevelRewards' : 'puzzleLevelRewards']: updatedRewards
        };
      }
      return prev;
    });
  };

  return (
    <PlayerStateContext.Provider value={{ 
      playerState, 
      setPlayerState, 
      resetPlayerState,
      addItem,
      getNewRecipe,
      incrementPuzzleCompletionCount,
      getPuzzleCompletionCount,
      checkAndAddLevelReward,
      checkAndAddPuzzleLevelReward,
      claimReward
    }}>
      {children}
    </PlayerStateContext.Provider>
  );
};

export const usePlayerState = () => {
  return useContext(PlayerStateContext);
};

export { getNewRecipe, addItemToInventory };