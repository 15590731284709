import React, { Component } from 'react';
import styles from './SetupModal.module.css';

class SetupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playerName: '',
      characterType: '',
    };
  }

  handleNameChange = (e) => {
    this.setState({ playerName: e.target.value });
  };

  handleCharacterTypeChange = (type) => {
    this.setState({ characterType: type });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.playerName && this.state.characterType) {
      this.props.onSubmit(this.state.playerName, this.state.characterType);
    }
  };

  render() {
    const isFormValid = this.state.playerName && this.state.characterType;

    return (
      <div className={styles.modalBackdrop}>
        <div className={styles.modalContent}>
          <h2>Setup Your Player</h2>
          <form onSubmit={this.handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="playerName">Player Name:</label>
              <input
                type="text"
                id="playerName"
                value={this.state.playerName}
                onChange={this.handleNameChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label>Character Type:</label>
              <div className={styles.buttonGroup}>
                <button
                  type="button"
                  className={`${styles.characterButton} ${this.state.characterType === 'Witch' ? styles.active : ''}`}
                  onClick={() => this.handleCharacterTypeChange('Witch')}
                >
                  Witch
                </button>
                <button
                  type="button"
                  className={`${styles.characterButton} ${this.state.characterType === 'Wizard' ? styles.active : ''}`}
                  onClick={() => this.handleCharacterTypeChange('Wizard')}
                >
                  Wizard
                </button>
              </div>
            </div>
            <button type="submit" className={styles.submitButton} disabled={!isFormValid}>
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default SetupModal;