import React, { useState, useEffect, useCallback } from 'react';
import styles from './MemoryGame.module.css';
import { usePlayerState, generateRandomGold, generateRandomItem } from '../../context/PlayerStateContext';
import { mapItems } from '../../constants/itemConstants';
import PrizeModal from '../additional/PrizeModal';

const gems = [
  { name: "Alpine Essence", image: "/Potions/Alpine Essence.png" },
  { name: "Blood moon Elixir", image: "/Potions/Blood moon Elixir.png" },
  { name: "Brew of Agility", image: "/Potions/Brew of Agility.png" },
  { name: "Brew of Elemental Resistance", image: "/Potions/Brew of Elemental Resistance.png" },
  { name: "Brew of Shadows", image: "/Potions/Brew of Shadows.png" },
  { name: "Brew of Teleportation", image: "/Potions/Brew of Teleportation.png" },
  { name: "Celestial Sphere Elixir", image: "/Potions/Celestial Sphere Elixir.png" },
  { name: "Draught of Arcane Sight", image: "/Potions/Draught of Arcane Sight.png" },
  { name: "Draught of Holy Water", image: "/Potions/Draught of Holy Water.png" },
  { name: "Draught of Lightning", image: "/Potions/Draught of Lightning.png" }
];

const MemoryGame = () => {
  const { playerState, setPlayerState, addItem, incrementPuzzleCompletionCount } = usePlayerState();
  const [cards, setCards] = useState([]);
  const [flipped, setFlipped] = useState([]);
  const [solved, setSolved] = useState([]);
  const [moves, setMoves] = useState(0);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60);
  const [gameActive, setGameActive] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showPrizeModal, setShowPrizeModal] = useState(false);
  const [prize, setPrize] = useState({ gold: 0, item: null, score: 0 });
  const [isWin, setIsWin] = useState(false);
  const [earnedReward, setEarnedReward] = useState(null);

  const requiredScore = 60; // Set the required score to win

  useEffect(() => {
    const timer = setTimeout(() => setVisible(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const shuffleCards = useCallback(() => {
    const shuffledDeck = [...gems, ...gems]
      .sort(() => Math.random() - 0.5)
      .map((gem, index) => ({ id: index, gem }));
    setCards(shuffledDeck);
  }, []);

  const earnReward = useCallback(() => {
    const gold = generateRandomGold();
    const mapId = 'noble_lands';
    const mapData = mapItems.find(map => map.id === mapId);
    const item = generateRandomItem(mapData.items);
    return { gold, item };
  }, []);

  const endGame = useCallback(() => {
    setGameActive(false);
    const finalScore = score;
    const isWinner = finalScore >= requiredScore;

    const earnedPrize = earnReward();
    setEarnedReward(earnedPrize);
    setPrize({
      gold: isWinner ? earnedPrize.gold : 0,
      item: isWinner ? earnedPrize.item : null,
      score: finalScore,
      goldImagePath: "/gold.png",
      experience: isWinner ? 0.02 : 0
    });

    setShowPrizeModal(true);
    setIsWin(isWinner);

    if (isWinner) {
      setPlayerState(prevState => ({
        ...prevState,
        gold: prevState.gold + earnedPrize.gold,
        level: prevState.level + 0.02
      }));

      incrementPuzzleCompletionCount('noble_lands', 4);
      
      if (earnedPrize.item) {
        addItem(earnedPrize.item);
      }
    }
  }, [score, earnReward, incrementPuzzleCompletionCount, addItem, requiredScore, setPlayerState]);

  useEffect(() => {
    if (timeLeft > 0 && gameActive) {
      const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0 && gameActive) {
      endGame();
    }
  }, [timeLeft, gameActive, endGame]);

  useEffect(() => {
    if (solved.length === gems.length * 2 && gameActive) {
      endGame();
    }
  }, [solved, endGame, gameActive]);

  const handleCardClick = (id) => {
    if (!gameActive || flipped.length === 2 || flipped.includes(id) || solved.includes(id)) return;

    const newFlipped = [...flipped, id];
    setFlipped(newFlipped);

    if (newFlipped.length === 2) {
      setMoves(moves + 1);
      const [firstId, secondId] = newFlipped;
      if (cards[firstId].gem.name === cards[secondId].gem.name) {
        setSolved([...solved, firstId, secondId]);
        setFlipped([]);
        setScore(prevScore => prevScore + 10); // Increase score for a match
      } else {
        setTimeout(() => setFlipped([]), 1000);
        setScore(prevScore => Math.max(0, prevScore - 1)); // Decrease score for a mismatch
      }
    }
  };

  const resetGame = () => {
    shuffleCards();
    setFlipped([]);
    setSolved([]);
    setMoves(0);
    setScore(0);
    setTimeLeft(60);
    setGameActive(false);
    setShowPrizeModal(false);
    setIsWin(false);
    setEarnedReward(null);
  };

  const startGame = () => {
    setGameActive(true);
  };

  const handlePrizeModalClose = () => {
    resetGame();
    setShowPrizeModal(false);
  };

  return (
    <div>
      {!showPrizeModal ? (
        <div className={`${styles.gameContainer} ${visible ? styles.visible : ''}`}>
          <h2 className={styles.title}>Memory Match Game</h2>
          <p className={styles.instructions}>
            {gameActive 
              ? "Flip the cards and find matching pairs! Try to complete the game in as few moves as possible."
              : `Match the gem pairs to score points! You need ${requiredScore} points to win. Click Start to begin.`}
          </p>
          {!gameActive && (
            <button className={styles.startButton} onClick={() => { resetGame(); startGame(); }}>
              Start Game
            </button>
          )}
          {gameActive && (
            <>
              <div className={styles.gameBoard}>
                {cards.map(card => (
                  <div
                    key={card.id}
                    className={`${styles.card} ${(flipped.includes(card.id) || solved.includes(card.id)) ? styles.flipped : ''}`}
                    onClick={() => handleCardClick(card.id)}
                  >
                    {(flipped.includes(card.id) || solved.includes(card.id)) 
                      ? <img src={card.gem.image} alt={card.gem.name} className={styles.gemImage} />
                      : '?'}
                  </div>
                ))}
              </div>
              <div className={styles.gameInfo}>
                Score: {score} | Moves: {moves} | Time Left: {timeLeft}s
              </div>
            </>
          )}
        </div>
      ) : (
        <PrizeModal 
          isOpen={showPrizeModal} 
          onClose={handlePrizeModalClose} 
          prize={prize}
          isWin={isWin}
          playerGold={playerState.gold}
          earnedReward={earnedReward}
        />
      )}
    </div>
  );
};

export default MemoryGame;