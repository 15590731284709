import React, { createContext, useContext, useRef } from 'react';

const AudioContext = createContext();

export const useAudio = () => {
  return useContext(AudioContext);
};

export const AudioProvider = ({ children }) => {
  const audioRef = useRef(new Audio('/WoodlandFantasy.mp3'));

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.loop = true; // Ensure the audio loops
      audioRef.current.play().catch(error => {
        console.error('Error playing audio:', error);
      });
    }
  };

  return (
    <AudioContext.Provider value={{ playAudio }}>
      {children}
    </AudioContext.Provider>
  );
};