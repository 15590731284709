// LandingPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LandingPage.module.css';
import { useAudio } from '../context/AudioContext';
import MysticText from './additional/MysticText';
import StartJourneyText from './additional/StartYourJourneyText';
import GameWrapper from './GameWrapper';

const LandingPage = () => {
  const navigate = useNavigate();
  const { playAudio } = useAudio();

  const handleStart = () => {
    playAudio();
    navigate('/loading');
  };

  return (
    <GameWrapper backgroundImage="/Landing.webp">
      <MysticText />
      <button onClick={handleStart} className={styles.startButton}>Start</button>
      <StartJourneyText />
    </GameWrapper>
  );
};


export default LandingPage;