import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AtlantisRetreat.module.css";
import GameTemplate from "../games/GameTemplate";
import BubblePopLogic from "../games/BubblePop";
import GameWrapper from "../GameWrapper";
const AtlantisRetreat = () => {
  const navigate = useNavigate();

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <GameWrapper backgroundImage="/AtlantisRetreat.webp">
      <button
        onClick={() => handleButtonClick("/home")}
        className={`${styles.homeButton} ${styles.button}`}
      >
        Home
      </button>

      <button
        onClick={() => handleButtonClick("/map")}
        className={`${styles.mapButton} ${styles.button}`}
      >
        Map
      </button>

      <BubblePopLogic />
    </GameWrapper>
  );
};

export default AtlantisRetreat;
