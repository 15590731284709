// src/constants/itemConstants.js

export const itemsData = {
  potions: [
    {
      name: "Alpine Essence",
      imagePath: "/Potions/Alpine Essence.png",
    },
    {
      name: "Blood moon Elixir",
      imagePath: "/Potions/Blood moon Elixir.png",
    },
    {
      name: "Brew of Agility",
      imagePath: "/Potions/Brew of Agility.png",
    },
    {
      name: "Brew of Elemental Resistance",
      imagePath: "/Potions/Brew of Elemental Resistance.png",
    },
    {
      name: "Brew of Shadows",
      imagePath: "/Potions/Brew of Shadows.png",
    },
    {
      name: "Brew of Teleportation",
      imagePath: "/Potions/Brew of Teleportation.png",
    },
    {
      name: "Celestial Sphere Elixir",
      imagePath: "/Potions/Celestial Sphere Elixir.png",
    },
    {
      name: "Draught of Arcane Sight",
      imagePath: "/Potions/Draught of Arcane Sight.png",
    },
    {
      name: "Draught of Holy Water",
      imagePath: "/Potions/Draught of Holy Water.png",
    },
    {
      name: "Draught of Lightning",
      imagePath: "/Potions/Draught of Lightning.png",
    },
    {
      name: "Draught of Stoneskin",
      imagePath: "/Potions/Draught of Stoneskin.png",
    },
    {
      name: "Draught of Summoning",
      imagePath: "/Potions/Draught of Summoning.png",
    },
    {
      name: "Earthbound Elixir",
      imagePath: "/Potions/Earthbound Elixir.png",
    },
    {
      name: "Elixir of Arcane Power",
      imagePath: "/Potions/Elixir of Arcane Power.png",
    },
    {
      name: "Elixir of Clarity",
      imagePath: "/Potions/Elixir of Clarity.png",
    },
    {
      name: "Elixir of Dragon's Breath",
      imagePath: "/Potions/Elixir of Dragon's Breath.png",
    },
    {
      name: "Elixir of Eternal Youth",
      imagePath: "/Potions/Elixir of Eternal Youth.png",
    },
    {
      name: "Elixir of Fortitude",
      imagePath: "/Potions/Elixir of Fortitude.png",
    },
    {
      name: "Elixir of Life",
      imagePath: "/Potions/Elixir of Life.png",
    },
    {
      name: "Elixir of Moss",
      imagePath: "/Potions/Elixir of Moss.png",
    },
    {
      name: "Elixir of Perfect Health",
      imagePath: "/Potions/Elixir of Perfect Health.png",
    },
    {
      name: "Elixir of Vitality",
      imagePath: "/Potions/Elixir of Vitality.png",
    },
    {
      name: "Fairy Dust Elixir",
      imagePath: "/Potions/Fairy Dust Elixir.png",
    },
    {
      name: "Golden Potion of Might",
      imagePath: "/Potions/Golden Potion of Might.png",
    },
    {
      name: "Mermaid's Whisper Elixir",
      imagePath: "/Potions/Mermaid's Whisper Elixir.png",
    },
    {
      name: "Morning Potion",
      imagePath: "/Potions/Morning Potion.png",
    },
    {
      name: "Nimbus Drift Potion",
      imagePath: "/Potions/Nimbus Drift Potion.png",
    },
    {
      name: "Potion of Common",
      imagePath: "/Potions/Potion of Common.png",
    },
    {
      name: "Potion of Deep Sea",
      imagePath: "/Potions/Potion of Deep Sea.png",
    },
    {
      name: "Potion of Growth",
      imagePath: "/Potions/Potion of Growth.png",
    },
    {
      name: "Potion of Invisibility",
      imagePath: "/Potions/Potion of Invisibility.png",
    },
    {
      name: "Potion of joy",
      imagePath: "/Potions/Potion of joy.png",
    },
    {
      name: "Potion of Mana",
      imagePath: "/Potions/Potion of Mana.png",
    },
    {
      name: "Potion of Necromancy",
      imagePath: "/Potions/Potion of Necromancy.png",
    },
    {
      name: "Potion of Noble",
      imagePath: "/Potions/Potion of Noble.png",
    },
    {
      name: "Potion of Protection",
      imagePath: "/Potions/Potion of Protection.png",
    },
    {
      name: "Potion of Regeneration",
      imagePath: "/Potions/Potion of Regeneration.png",
    },
    {
      name: "Potion of Sage",
      imagePath: "/Potions/Potion of Sage.png",
    },
    {
      name: "Potion of",
      imagePath: "/Potions/Potion of.png",
    },
    {
      name: "Red Sea Elixir",
      imagePath: "/Potions/Red Sea Elixir.png",
    },
    {
      name: "Seashell Serenity Elixir",
      imagePath: "/Potions/Seashell Serenity Elixir.png",
    },
    {
      name: "Snake Venom",
      imagePath: "/Potions/Snake Venom.png",
    },
    {
      name: "Solar Radiance Elixir",
      imagePath: "/Potions/Solar Radiance Elixir.png",
    },
    {
      name: "Spider Venom",
      imagePath: "/Potions/Spider Venom.png",
    },
    {
      name: "Tonic of Elemental Fury",
      imagePath: "/Potions/Tonic of Elemental Fury.png",
    },
    {
      name: "Tonic of Inferno",
      imagePath: "/Potions/Tonic of Inferno.png",
    },
    {
      name: "Tonic of Mind Shield",
      imagePath: "/Potions/Tonic of Mind Shield.png",
    },
    {
      name: "Tonic of Stealth",
      imagePath: "/Potions/Tonic of Stealth.png",
    },
    {
      name: "Tonic of Wisdom",
      imagePath: "/Potions/Tonic of Wisdom.png",
    },
    {
      name: "Vine's Bounty Elixir",
      imagePath: "/Potions/Vine's Bounty Elixir.png",
    },
    {
      name: "Void's Enigma Potion",
      imagePath: "/Potions/Void's Enigma Potion.png",
    },
  ],
  ingredients: [
    {
      name: "Ancient Leaves",
      imagePath: "/Ingredients/Ancient Leaves.png",
    },
    {
      name: "Ancient Oak Bark",
      imagePath: "/Ingredients/Ancient Oak Bark.png",
    },
    {
      name: "Ants",
      imagePath: "/Ingredients/Ants.png",
    },
    {
      name: "Baby Frogs",
      imagePath: "/Ingredients/Baby Frogs.png",
    },
    {
      name: "Bats",
      imagePath: "/Ingredients/Bats.png",
    },
    {
      name: "Blue Ants",
      imagePath: "/Ingredients/Blue Ants.png",
    },
    {
      name: "Blue Eggs",
      imagePath: "/Ingredients/Blue Eggs.png",
    },
    {
      name: "Blue Feathers",
      imagePath: "/Ingredients/Blue Feathers.png",
    },
    {
      name: "Butterflies",
      imagePath: "/Ingredients/Butterflies.png",
    },
    {
      name: "Caterpillars",
      imagePath: "/Ingredients/Caterpillars.png",
    },
    {
      name: "Christmas Beetles",
      imagePath: "/Ingredients/Christmas Beetles.png",
    },
    {
      name: "Cinimon Scrolls",
      imagePath: "/Ingredients/Cinimon Scrolls.png",
    },
    {
      name: "Coral",
      imagePath: "/Ingredients/Coral.png",
    },
    {
      name: "Cosmic Planet Dust",
      imagePath: "/Ingredients/Cosmic Planet Dust.png",
    },
    {
      name: "Crabs",
      imagePath: "/Ingredients/Crabs.png",
    },
    {
      name: "Dragon Bones",
      imagePath: "/Ingredients/Dragon Bones.png",
    },
    {
      name: "Dragon Scales",
      imagePath: "/Ingredients/Dragon Scales.png",
    },
    {
      name: "Elven Leaves",
      imagePath: "/Ingredients/Elven Leaves.png",
    },
    {
      name: "Enchanted Acorns",
      imagePath: "/Ingredients/Enchanted Acorns.png",
    },
    {
      name: "Enchanted Apples",
      imagePath: "/Ingredients/Enchanted Apples.png",
    },
    {
      name: "Figs",
      imagePath: "/Ingredients/Figs.png",
    },
    {
      name: "Finger Limes",
      imagePath: "/Ingredients/Finger Limes.png",
    },
    {
      name: "Fire Flies",
      imagePath: "/Ingredients/Fire Flies.png",
    },
    {
      name: "Four Leaf Clovers",
      imagePath: "/Ingredients/Four Leaf Clovers.png",
    },
    {
      name: "Fox Tails",
      imagePath: "/Ingredients/Fox Tails.png",
    },
    {
      name: "Frogs",
      imagePath: "/Ingredients/Frogs.png",
    },
    {
      name: "Glowing Chillies",
      imagePath: "/Ingredients/Glowing Chillies.png",
    },
    {
      name: "Horse Hair",
      imagePath: "/Ingredients/Horse Hair.png",
    },
    {
      name: "Lava",
      imagePath: "/Ingredients/Lava.png",
    },
    {
      name: "Lemons",
      imagePath: "/Ingredients/Lemons.png",
    },
    {
      name: "Lightning",
      imagePath: "/Ingredients/Lightning.png",
    },
    {
      name: "Lizards",
      imagePath: "/Ingredients/Lizards.png",
    },
    {
      name: "Magic Sand",
      imagePath: "/Ingredients/Magic Sand.png",
    },
    {
      name: "Magical Moss",
      imagePath: "/Ingredients/Magical Moss.png",
    },
    {
      name: "Mantis",
      imagePath: "/Ingredients/Mantis.png",
    },
    {
      name: "Moon Dust",
      imagePath: "/Ingredients/Moon Dust.png",
    },
    {
      name: "Mushrooms",
      imagePath: "/Ingredients/Mushrooms.png",
    },
    {
      name: "Octopus Legs",
      imagePath: "/Ingredients/Octopus Legs.png",
    },
    {
      name: "Orchard Swallow Tail Butterflies",
      imagePath: "/Ingredients/Orchard Swallow Tail Butterflies.png",
    },
    {
      name: "Passion Flower",
      imagePath: "/Ingredients/Passion Flower.png",
    },
    {
      name: "Phoniex Feathers",
      imagePath: "/Ingredients/Phoniex Feathers.png",
    },
    {
      name: "Poison Berries",
      imagePath: "/Ingredients/Poison Berries.png",
    },
    {
      name: "Prawns",
      imagePath: "/Ingredients/Prawns.png",
    },
    {
      name: "Pumpkins",
      imagePath: "/Ingredients/Pumpkins.png",
    },
    {
      name: "Purple Flowers",
      imagePath: "/Ingredients/Purple Flowers.png",
    },
    {
      name: "Rainbow Magic Beans",
      imagePath: "/Ingredients/Rainbow Magic Beans.png",
    },
    {
      name: "Rainbow Roses",
      imagePath: "/Ingredients/Rainbow Roses.png",
    },
    {
      name: "Red Lillys",
      imagePath: "/Ingredients/Red Lillys.png",
    },
    {
      name: "Rosemary",
      imagePath: "/Ingredients/Rosemary.png",
    },
    {
      name: "Spider Web",
      imagePath: "/Ingredients/Spider Web.png",
    },
    {
      name: "Star Anise",
      imagePath: "/Ingredients/Star Anise.png",
    },
    {
      name: "Sunflower Seeds",
      imagePath: "/Ingredients/Sunflower Seeds.png",
    },
    {
      name: "Tornado",
      imagePath: "/Ingredients/Tornado.png",
    },
    {
      name: "Troll Toe Nails",
      imagePath: "/Ingredients/Troll Toe Nails.png",
    },
    {
      name: "Unicorn Horns",
      imagePath: "/Ingredients/Unicorn Horns.png",
    },
    {
      name: "Wheat",
      imagePath: "/Ingredients/Wheat.png",
    },
    {
      name: "Wolf Teeth",
      imagePath: "/Ingredients/Wolf Teeth.png",
    },
    {
      name: "Worms",
      imagePath: "/Ingredients/Worms.png",
    },
    {
      name: "Yellow Feathers",
      imagePath: "/Ingredients/Yellow Feathers.png",
    },
    {
      name: "Yellow Spiders",
      imagePath: "/Ingredients/Yellow Spiders.png",
    },
  ],
  runes: [
    { name: "Ansuz", imagePath: "/Runes/Ansuz.png" },
    { name: "Berkana", imagePath: "/Runes/Berkana.png" },
    { name: "Ehwaz", imagePath: "/Runes/Ehwaz.png" },
    { name: "Hagalaz", imagePath: "/Runes/Hagalaz.png" },
    { name: "Isa", imagePath: "/Runes/Isa.png" },
    { name: "Kenaz", imagePath: "/Runes/Kenaz.png" },
    { name: "Laguz", imagePath: "/Runes/Laguz.png" },
    { name: "Mannaz", imagePath: "/Runes/Mannaz.png" },
    { name: "Othala", imagePath: "/Runes/Othala.png" },
    { name: "Uruz", imagePath: "/Runes/Uruz.png" },
    { name: "Fehu", imagePath: "/Runes/Fehu.png" },
    { name: "Gebo", imagePath: "/Runes/Gebo.png" },
    { name: "Ingwaz", imagePath: "/Runes/Ingwaz.png" },
    { name: "Jera", imagePath: "/Runes/Jera.png" },
    { name: "Perthro", imagePath: "/Runes/Perthro.png" },
    { name: "Raido", imagePath: "/Runes/Raido.png" },
    { name: "Sowilo", imagePath: "/Runes/Sowilo.png" },
    { name: "Teiwaz", imagePath: "/Runes/Teiwaz.png" },
    { name: "Thurisaz", imagePath: "/Runes/Thurisaz.png" },
    { name: "Wunjo", imagePath: "/Runes/Wunjo.png" },
    { name: "Algiz", imagePath: "/Runes/Algiz.png" },
    { name: "Eihwaz", imagePath: "/Runes/Eihwaz.png" },
    { name: "Dagaz", imagePath: "/Runes/Dagaz.png" },
    { name: "Naudhiz", imagePath: "/Runes/Naudhiz.png" },
  ],

  gemstones: [
    { name: "Ruby", imagePath: "/Gemstones/Ruby.png" },
    { name: "Sapphire", imagePath: "/Gemstones/Sapphire.png" },
    { name: "Emerald", imagePath: "/Gemstones/Emerald.png" },
    { name: "Diamond", imagePath: "/Gemstones/Diamond.png" },
    { name: "Amethyst", imagePath: "/Gemstones/Amethyst.png" },
    { name: "Topaz", imagePath: "/Gemstones/Topaz.png" },
    { name: "Opal", imagePath: "/Gemstones/Opal.png" },
    { name: "Onyx", imagePath: "/Gemstones/Onyx.png" },
    { name: "Jade", imagePath: "/Gemstones/Jade.png" },
    { name: "Moonstone", imagePath: "/Gemstones/Moonstone.png" },
    { name: "Aquamarine", imagePath: "/Gemstones/Aquamarine.png" },
    { name: "Garnet", imagePath: "/Gemstones/Garnet.png" },
    { name: "Peridot", imagePath: "/Gemstones/Peridot.png" },
    { name: "Citrine", imagePath: "/Gemstones/Citrine.png" },
    { name: "Turquoise", imagePath: "/Gemstones/Turquoise.png" },
    { name: "Spinel", imagePath: "/Gemstones/Spinel.png" },
    { name: "Tanzanite", imagePath: "/Gemstones/Tanzanite.png" },
    { name: "Tourmaline", imagePath: "/Gemstones/Tourmaline.png" },
    { name: "Lapis Lazuli", imagePath: "/Gemstones/LapisLazuli.png" },
    { name: "Zircon", imagePath: "/Gemstones/Zircon.png" },
  ],
};

export const mapItems = [
  {
    id: "noble_lands",
    items: {
      potions: itemsData.potions.slice(0, 20),
      ingredients: itemsData.ingredients.slice(0, 20),
      runes: itemsData.runes.slice(0, 20),
      gemstones: itemsData.gemstones.slice(0, 20),
    },
  },
];

export const recipe_names = [
  "Creature of Darkness and Wonder",
  "Beast of Twilight's Embrace",
  "Chimera of the Forgotten Realms",
  "Wyrm of the Celestial Winds",
  "Phoenix of Eternal Flame",
  "Kraken of the Abyssal Depths",
  "Unicorn of the Enchanted Grove",
  "Dragon of the Crystal Caverns",
  "Gryphon of the Stormy Peaks",
  "Basilisk of the Petrified Forest",
  "Hydra of the Venomous Marsh",
  "Manticore of the Scorching Sands",
  "Leviathan of the Frozen Seas",
  "Cerberus of the Underworld Gates",
  "Pegasus of the Rainbow Skies",
  "Sphinx of the Riddle Ruins",
  "Kitsune of the Mystic Bamboo",
  "Fenrir of the Lunar Howl",
  "Banshee of the Misty Moors",
  "Djinn of the Cosmic Winds",
];

export const creatures = [
  {
    name: "Starstrider Stag",
    imagePath: "/Creatures/StarstriderStag.jpg",
    description:
      "The Starstrider Stag is a mystical being with a silvery coat woven from stardust and constellations dancing upon its back, while its antlers adorned with celestial orbs cast an ethereal light. Its deep, knowing eyes reflect the universe's mysteries, making it a guardian of the night and a beacon of hope.",
    stats: { power: 7, strength: 6, agility: 5, intelligence: 4, luck: 8 },
  },
  {
    name: "Beast of Twilight's Embrace",
    imagePath: "/Creatures/BeastOfTwilightEmbrace.webp",
    description:
      "The Beast of Twilight's Embrace is an enchanting creature with a velvety night-sky coat sprinkled with twinkling stars and delicate, luminous wings that shimmer with cosmic hues. Its large, gleaming eyes radiate curiosity and magic, making it a beloved guardian of the enchanted forest.",
    stats: { power: 6, strength: 8, agility: 5, intelligence: 5, luck: 6 },
  },
  {
    name: "Moonshadow Dragon",
    imagePath: "/Creatures/MoonshadowDragon.webp",
    description:
      "The Moonshadow Dragon is a whimsical and friendly creature with a coat of midnight blue adorned with celestial patterns and delicate, iridescent wings that reflect the colors of the night sky. Its large, twinkling eyes and playful demeanor make it a beloved companion in the enchanted forest, embodying both the mysteries of darkness and the wonder of the stars.",
    stats: { power: 8, strength: 7, agility: 6, intelligence: 5, luck: 4 },
  },
  {
    name: "Chimera of the Forgotten Realms",
    imagePath: "/Creatures/ChimeraOfTheForgottenRealms.webp",
    description:
      "The Chimera of the Forgotten Realms is a magnificent creature with the body of a lion and the scales of a dragon, crowned with two regal heads—one feline and one draconic. Its vibrant, multicolored wings shimmer with a fiery glow, and its playful yet majestic presence captivates all who encounter it.",
    stats: { power: 7, strength: 6, agility: 8, intelligence: 4, luck: 5 },
  },
  {
    name: "Kraken of the Abyssal Depths",
    imagePath: "/Creatures/KrakenOfTheAbyssalDepths.webp",
    description:
      "The Kraken of the Abyssal Depths is a mesmerizing creature with shimmering scales that mirror the starlit ocean. Its large, curious eyes and flowing, bioluminescent tendrils illuminate the dark waters, embodying both the mystery and wonder of the deep sea.",
    stats: { power: 9, strength: 5, agility: 7, intelligence: 5, luck: 6 },
  },
  {
    name: "Basilisk of the Petrified Forest",
    imagePath: "/Creatures/BasiliskOfThePetrifiedForest.webp",
    description:
      "The Basilisk of the Petrified Forest is a charming creature with emerald scales and leafy crest, resembling an ancient forest spirit. Its large, expressive eyes and intricate markings reflect the magic of the forest, making it a symbol of nature's timeless wonders.",
    stats: { power: 8, strength: 9, agility: 3, intelligence: 4, luck: 6 },
  },
  {
    name: "Crystalwing Serpent",
    imagePath: "/Creatures/CrystalwingSerpent.webp",
    description:
      "The Crystalwing Serpent is a majestic creature adorned with shimmering silver scales and radiant, crystalline fins. Its large, luminous eyes reflect the magic of the ancient forest, making it a revered guardian of the mystical realm, embodying both grace and enchantment.",
    stats: { power: 6, strength: 5, agility: 7, intelligence: 6, luck: 6 },
  },
  {
    name: "Trident Hydra",
    imagePath: "/Creatures/TridentHydra.webp",
    description:
      "The Trident Hydra is a mesmerizing creature with three serpentine heads, each crowned with glowing, bioluminescent tendrils. Its emerald scales shimmer with hints of violet, and its piercing eyes radiate both wisdom and mystery, making it a formidable guardian of the enchanted marshlands.",
    stats: { power: 9, strength: 8, agility: 4, intelligence: 5, luck: 5 },
  },
  {
    name: "Luminous Serpenta",
    imagePath: "/Creatures/LuminousSerpenta.webp",
    description:
      "The Luminous Serpenta is an enchanting creature with three elegant heads and a shimmering emerald body adorned with bioluminescent patterns. Its large, violet eyes and iridescent frills exude both charm and mystery, making it a captivating guardian of the mystical marshlands.",
    stats: { power: 7, strength: 6, agility: 8, intelligence: 5, luck: 4 },
  },
  {
    name: "Celestial Leonix",
    imagePath: "/Creatures/CelestialLeonix.webp",
    description:
      "The Celestial Leonix is a magnificent creature with a regal lion's mane, spiraled golden horns, and radiant, fiery wings. Its intricately patterned body glows with an inner light, and its large, soulful eyes reflect the mysteries of the cosmos, making it a revered guardian of the enchanted desert.",
    stats: { power: 8, strength: 7, agility: 6, intelligence: 5, luck: 4 },
  },
  {
    name: "Frostwhisper Serpent",
    imagePath: "/Creatures/FrostwhisperSerpent.webp",
    description:
      "The Frostwhisper Serpent is a breathtaking creature with glistening scales of icy blue and a mane of delicate, snow-like feathers. Its deep, sapphire eyes and ethereal presence evoke the beauty and mystery of the frozen seas, making it a majestic guardian of the icy realms.",
    stats: { power: 8, strength: 8, agility: 4, intelligence: 5, luck: 5 },
  },
  {
    name: "Triumphant Cerulean",
    imagePath: "/Creatures/TriumphantCerulean.webp",
    description:
      "The Triumphant Cerulean is an awe-inspiring creature with three majestic heads, each with uniquely colored eyes—amber, sapphire, and amethyst. Its luxurious mane and intricately adorned body radiate an aura of regal power and ancient wisdom, making it a revered guardian of the mystical realms.",
    stats: { power: 9, strength: 7, agility: 5, intelligence: 4, luck: 5 },
  },
  {
    name: "Cerberus of the Underworld Gates",
    imagePath: "/Creatures/CerberusOfTheUnderworldGates.webp",
    description:
      "The Cerberus of the Underworld Gates is a magnificent creature with three elegantly furred heads, each glowing with a different mystical light—golden, silver, and blue. Its sleek, flowing mane and intricate markings symbolize the unity of night and day, making it a powerful and enigmatic guardian of the celestial gates.",
    stats: { power: 8, strength: 9, agility: 3, intelligence: 4, luck: 6 },
  },
  {
    name: "Pegasus of the Rainbow Skies",
    imagePath: "/Creatures/PegasusOfTheRainbowSkies.webp",
    description:
      "The Pegasus of the Rainbow Skies is a stunning creature with a shimmering coat of pure white, adorned with swirling rainbow patterns and glittering hooves. Its majestic wings, reflecting every color of the spectrum, and its luminous horn make it a beacon of light and magic in the enchanted skies.",
    stats: { power: 8, strength: 8, agility: 5, intelligence: 4, luck: 5 },
  },
  {
    name: "Sphinx of the Riddle Ruins",
    imagePath: "/Creatures/SphinxOfTheRiddleRuins.webp",
    description:
      "The Sphinx of the Riddle Ruins is a majestic creature with a radiant golden coat, intricate ancient markings, and eyes that gleam with wisdom. Its magnificent wings and regal adornments make it a revered guardian of ancient ruins, embodying both mystery and enlightenment.",
    stats: { power: 6, strength: 6, agility: 7, intelligence: 8, luck: 3 },
  },
  {
    name: "Sunlit Leonis",
    imagePath: "/Creatures/SunlitLeonis.webp",
    description:
      "The Sunlit Leonis is a magnificent creature with a golden mane, adorned with intricate, ancient markings and radiant blue gems. Its powerful wings shimmer with the light of the sun, and its wise, gentle eyes reflect the wisdom of the ages, making it a revered guardian of mystical ruins.",
    stats: { power: 7, strength: 4, agility: 8, intelligence: 7, luck: 4 },
  },
  {
    name: "Celestial Foxlore",
    imagePath: "/Creatures/CelestialFoxlore.webp",
    description:
      "The Celestial Foxlore is a stunning creature with a fiery red coat adorned with intricate, glowing patterns and jeweled accents. Its multiple, flowing tails and luminous eyes exude an aura of mystic charm, making it a captivating guardian of the enchanted bamboo forest.",
    stats: { power: 9, strength: 8, agility: 5, intelligence: 4, luck: 4 },
  },
  {
    name: "Moonlit Foxhound",
    imagePath: "/Creatures/MoonlitFoxhound.webp",
    description:
      "The Moonlit Foxhound is a mystical creature with a shimmering silver coat adorned with celestial patterns and radiant blue eyes. Its majestic presence under the moonlight and intricate designs make it a revered guardian of the enchanted night.",
    stats: { power: 6, strength: 4, agility: 7, intelligence: 8, luck: 5 },
  },
  {
    name: "Leafsprite Sylph",
    imagePath: "/Creatures/LeafspriteSylph.webp",
    description:
      "The Leafsprite Sylph is a delicate and enchanting forest spirit with flowing green hair, ethereal wings, and sparkling eyes. Adorned with leaves and blossoms, this mystical creature embodies the very essence of the Whispering Woods, bringing magic and wonder to all who encounter her.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Mistveil Fawn",
    imagePath: "/Creatures/MistveilFawn.webp",
    description:
      "The Mistveil Fawn is an ethereal and enchanting creature with a delicate, shimmering coat and flowing pastel mane. Its large, luminous eyes and intricate, mystical patterns make it a captivating presence in the misty moors, embodying both beauty and wonder.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Wispwing Sprite",
    imagePath: "/Creatures/WispwingSprite.webp",
    description:
      "The Wispwing Sprite is a delicate, ethereal creature with flowing, silken fur and luminous, wide eyes. Its graceful, swirling form and soft pastel hues make it a mesmerizing presence, embodying the enchanting beauty of misty realms.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Verdant Drakonis",
    imagePath: "/Creatures/VerdantDrakonis.webp",
    description:
      "The Verdant Drakonis is a magnificent creature with a sleek, emerald-green body covered in shimmering scales. Its luminous wings and piercing golden eyes reflect the ancient magic of the enchanted forest, making it a powerful and revered guardian of nature's secrets.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Glimmerwing Sprite",
    imagePath: "/Creatures/GlimmerwingSprite.webp",
    description:
      "The Glimmerwing Sprite is a delicate, enchanting creature with a butterfly-like body and iridescent wings that shimmer with every color of the rainbow. Its large, sparkling eyes and luminescent aura make it a captivating and magical presence in the enchanted meadow.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Starry Winged Leonara",
    imagePath: "/Creatures/StarryWingedLeonara.webp",
    description:
      "The Starry Winged Leonara is a majestic creature with the body of a lion and wings that shimmer with the constellations of the night sky. Its midnight blue fur is adorned with celestial patterns, and its golden eyes glow with the wisdom of the cosmos, making it a revered guardian of the starry heavens.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Sapphire Sea Serpent",
    imagePath: "/Creatures/SapphireSeaSerpent.jpg",
    description:
      "The Sapphire Sea Serpent is a magnificent aquatic creature with a long, sinuous body covered in shimmering, sapphire-blue scales. Its fin-like appendages and glowing blue eyes reflect the deep ocean's mysteries, making it a majestic and enigmatic guardian of the underwater realms.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Aurora Lynx",
    imagePath: "/Creatures/AuroraLynx.jpg",
    description:
      "The Aurora Lynx is a mystical creature with sleek, silvery-white fur that shimmers with hues of the Northern Lights. Its deep blue eyes and glowing markings reflect the beauty of the auroras, making it a mesmerizing guardian of the boreal forest.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Gemscale Dragon",
    imagePath: "/Creatures/GemscaleDragon.jpg",
    description:
      "The Gemscale Dragon is a magnificent creature with a sleek, dragon-like body covered in iridescent, gemstone-like scales that shimmer with a kaleidoscope of colors. Its radiant wings and piercing blue eyes make it a mesmerizing guardian of the enchanted cavern, where the walls sparkle with countless crystals.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Luminara Flutter",
    imagePath: "/Creatures/LuminaraFlutter.jpg",
    description:
      "The Luminara Flutter is a breathtaking creature with large, iridescent wings that shimmer with a myriad of vibrant colors. Its radiant, gem-like body and glowing blue eyes make it a dazzling presence in the enchanted grove, illuminating the surroundings with its magical light.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Radient Turtle of the Cosmic Seas",
    imagePath: "/Creatures/RadientTurtleOfTheCosmicSeas.jpg",
    description:
      "The Radient Turtle of the Cosmic Seas is a magnificent creature with a large, iridescent shell that shimmers with all the colors of the cosmos. Its glowing patterns and gentle, wise eyes make it a mesmerizing guardian of the vibrant, enchanted reef, illuminating the underwater world with its magical light.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Lunaris Draconis of the Celestial Abyss",
    imagePath: "/Creatures/LunarisDraconisOfTheCelestialAbyss.jpg",
    description:
      "The Lunaris Draconis of the Celestial Abyss is an awe-inspiring creature with a sleek, silver-blue body that shimmers with a celestial glow. Its luminescent scales and piercing blue eyes reflect the mysteries of the moonlit skies, making it a majestic guardian of the lunar realm.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Astral Mouselight",
    imagePath: "/Creatures/AstralMouselight.jpg",
    description:
      "The Astral Mouselight is a delicate and enchanting creature with a coat that glimmers with silver and blue hues, reflecting the starlit sky. Its translucent wings and large, luminous eyes make it a magical guardian of the celestial meadow, bringing a touch of the cosmos to the earthly realm.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Stardust Fennec",
    imagePath: "/Creatures/StardustFennec.jpg",
    description:
      "The Stardust Fennec is a mystical creature with a soft, shimmering coat that sparkles with the colors of the galaxy. Its large, glowing eyes and feathery tail make it a captivating guardian of the starlit glade, embodying the magic and wonder of the night sky.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Glacial Guardian Bear of the Icelands",
    imagePath: "/Creatures/GlacialGuardianBearOfTheIcelands.jpg",
    description:
      "The Glacial Guardian Bear is a formidable creature with a thick, white fur coat that glistens like snow. Its glowing blue eyes and immense presence make it a powerful protector of the frosted peaks, embodying the strength and majesty of the icy wilderness.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Ancient Granitehorn Ram",
    imagePath: "/Creatures/AncientGranitehornRam.jpg",
    description:
      "The Ancient Granitehorn Ram is a mighty creature with fur as tough as iron and imposing, spiraled horns. Its piercing blue eyes and formidable presence make it a powerful protector of the granite cliffs, embodying the rugged strength and resilience of the mountainous terrain.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Gecko of the Mystic Swamp",
    imagePath: "/Creatures/GeckoOfTheMysticSwamp.jpg",
    description:
      "The Gecko of the Mystic Swamp is an emerald-green lizard with iridescent scales that shimmer in hues of jade and gold. With eyes like liquid amber and an elusive grace, it weaves through the enchanted swamp, leaving a trail of phosphorescent mist and guarding its mystical realm with ancient enchantments.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Firestorm Fox of Embers",
    imagePath: "/Creatures/FirestormFoxOfEmbers.jpg",
    description:
      "The Firestorm Fox of Embers is a fiery creature with a sleek, crimson coat that flickers and dances like living flames. Its eyes glow with a molten intensity, and its tail blazes with ember-like sparks, leaving a trail of smoldering ash as it moves through its fiery domain.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Mouse of Moonlight",
    imagePath: "/Creatures/MouseOfMoonlight.jpg",
    description:
      "The Mouse of Moonlight is a delicate, silver-coated creature with fur that glows softly under the moon's gentle light. With eyes like twinkling stars and a graceful, ethereal presence, it moves silently through the night, leaving a trail of shimmering moonbeams in its wake.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
  {
    name: "Forest Warden",
    imagePath: "/Creatures/ForestWarden.jpg",
    description:
      "The Forest Warden is a towering, living entity crafted entirely from intertwining trees and vibrant leaves. Its form flows seamlessly with the forest, and its glowing, emerald eyes reflect the deep wisdom of the ancient woods, as it moves with the gentle sway of foliage to protect its realm.",
    stats: { power: 8, strength: 5, agility: 7, intelligence: 6, luck: 5 },
  },
];
