import React, { useState, useEffect, useCallback } from 'react';
import styles from './CipherText.module.css';
import { usePlayerState, generateRandomGold, generateRandomItem } from '../../context/PlayerStateContext';
import { mapItems } from '../../constants/itemConstants';
import PrizeModal from '../additional/PrizeModal';

const symbols = {
  A: '🜁', B: '🜂', C: '🜃', D: '🜄', E: '🜅', F: '🜆', G: '🜇', H: '🜈',
  I: '🜉', J: '🜊', K: '🜋', L: '🜌', M: '🜍', N: '🜎', O: '🜏', P: '🜐',
  Q: '🜑', R: '🜒', S: '🜓', T: '🜔', U: '🜕', V: '🜖', W: '🜗', X: '🜘',
  Y: '🜙', Z: '🜚', ' ': '⬚'
};

const phrases = [
  "MAGIC WAND", "FLYING BROOM", "WIZARD HAT", "CRYSTAL BALL", "FAIRY DUST",
  "DRAGON EGG", "UNICORN HORN", "POTION BOTTLE", "ENCHANTED FOREST", "MAGICAL AMULET",
  "SPELL BOOK", "TIME TURNER", "TALKING MIRROR", "GHOST WHISPER", "MERMAID SCALE",
  "PHOENIX FEATHER", "DRAGON SCALE", "MAGIC CARPET", "GOBLIN GOLD", "WITCH CAULDRON",
  "MAGIC BEANS", "PIXIE WINGS", "TROLL BRIDGE", "ELF ARROWS", "CENTAUR HOOVES",
  "GRIFFIN CLAW", "BASILISK FANG", "WEREWOLF FUR", "VAMPIRE FANGS", "GIANT FOOTPRINT",
  "LEPRECHAUN GOLD", "SIREN SONG", "KRAKEN TENTACLE", "CHIMERA TAIL", "HYDRA HEAD",
  "MEDUSA GAZE", "PEGASUS WING", "MINOTAUR HORN", "SPHINX RIDDLE", "CYCLOPS EYE",
  "HARPY FEATHER", "GNOME HAT", "OGRE CLUB", "BANSHEE WAIL", "GORGON SCALES",
  "NYMPH TEAR", "SATYR FLUTE", "MANTICORE STING", "CERBERUS COLLAR", "HIPPOGRIFF TALON",
  "DRAGON BREATH", "MERMAID SONG", "PIXIE DUST", "UNICORN BLOOD", "GIANT STRENGTH",
  "ELF MAGIC", "TROLL SKIN", "FAIRY LIGHT", "GOBLIN TRICK", "WITCH CURSE",
  "WIZARD SPELL", "GHOST TOUCH", "VAMPIRE BITE", "WEREWOLF HOWL", "PHOENIX REBIRTH",
  "GRIFFIN FLIGHT", "BASILISK STARE", "CENTAUR WISDOM", "SIREN CALL", "KRAKEN DEPTHS",
  "CHIMERA ROAR", "HYDRA REGENERATION", "MEDUSA STONE", "PEGASUS CLOUD", "MINOTAUR MAZE",
  "SPHINX SECRET", "CYCLOPS STRENGTH", "HARPY SCREECH", "GNOME TREASURE", "OGRE MIGHT",
  "BANSHEE CRY", "GORGON PETRIFY", "NYMPH BEAUTY", "SATYR DANCE", "MANTICORE POISON",
  "CERBERUS GUARD", "HIPPOGRIFF RIDE", "DRAGON FIRE", "MERMAID KISS", "PIXIE TRICK",
  "UNICORN PURITY", "GIANT STOMP", "ELF STEALTH", "TROLL REGENERATION", "FAIRY CHARM",
  "GOBLIN GREED", "WITCH BREW"
];

const CipherTextPuzzle = () => {
  const { playerState, setPlayerState, addItem, incrementPuzzleCompletionCount } = usePlayerState();
  const [userInput, setUserInput] = useState('');
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(100);
  const [gameActive, setGameActive] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showPrizeModal, setShowPrizeModal] = useState(false);
  const [prize, setPrize] = useState({ gold: 0, item: null, score: 0 });
  const [isWin, setIsWin] = useState(false);
  const [currentPhrase, setCurrentPhrase] = useState('');
  const [cipherText, setCipherText] = useState('');
  const [incorrectFeedback, setIncorrectFeedback] = useState('');

  const requiredScore = 1;

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const generateNewPhrase = useCallback(() => {
    const newPhrase = phrases[Math.floor(Math.random() * phrases.length)];
    setCurrentPhrase(newPhrase);
    setCipherText(newPhrase.split('').map(letter => symbols[letter] || letter).join(' '));
    setUserInput('');
    setIncorrectFeedback('');
  }, []);

  const earnReward = useCallback(() => {
    const gold = generateRandomGold();
    const mapId = 'noble_lands';
    const mapData = mapItems.find(map => map.id === mapId);
    const item = generateRandomItem(mapData.items);
    return { gold, item };
  }, []);

  const endGame = useCallback((finalScore) => {
    setGameActive(false);
    const earnedPrize = earnReward();
    const isWinner = finalScore >= requiredScore;
    
    setPrize({
      gold: isWinner ? earnedPrize.gold : 0,
      item: isWinner ? earnedPrize.item : null,
      score: finalScore,
      goldImagePath: "/gold.png",
      experience: isWinner ? 0.02 : 0
    });

    setShowPrizeModal(true);
    setIsWin(isWinner);

    if (isWinner) {
      setPlayerState(prevState => ({
        ...prevState,
        gold: prevState.gold + earnedPrize.gold,
        level: prevState.level + 0.02,
      }));

      incrementPuzzleCompletionCount('noble_lands', 6);
      addItem(earnedPrize.item);
    }
  }, [requiredScore, earnReward, setPlayerState, addItem, incrementPuzzleCompletionCount]);

  const startGame = useCallback(() => {
    setScore(0);
    setTimeLeft(100);
    setGameActive(true);
    setShowPrizeModal(false);
    setIncorrectFeedback('');
    generateNewPhrase();
  }, [generateNewPhrase]);

  useEffect(() => {
    let timer;
    if (gameActive && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0 && gameActive) {
      endGame(score);
    }
    return () => clearInterval(timer);
  }, [timeLeft, gameActive, endGame, score]);

  const handleChange = (e) => {
    setUserInput(e.target.value.toUpperCase());
    setIncorrectFeedback('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInput === currentPhrase) {
      const newScore = 1;
      setScore(newScore);
      endGame(newScore);
    } else {
      setIncorrectFeedback('Incorrect. Try again!');
    }
  };

  return (
    <div>
      {!showPrizeModal ? (
        <div className={`${styles.puzzleContainer} ${visible ? styles.visible : ''}`}>
          <h2 className={styles.title}>Cipher Text Puzzle</h2>
          <p className={styles.instructions}>
            Decode the cipher text in 100 seconds to win!
          </p>
          <div className={styles.gameContent}>
            {gameActive ? (
              <>
                <div className={styles.cipherTextContainer}>
                  <div className={styles.cipherText}>{cipherText}</div>
                  <form onSubmit={handleSubmit} className={styles.form}>
                    <input 
                      type="text" 
                      value={userInput} 
                      onChange={handleChange} 
                      className={styles.input} 
                      maxLength={currentPhrase.length} 
                    />
                    <button type="submit" className={styles.button}>Submit</button>
                  </form>
                  {incorrectFeedback && <div className={styles.incorrect}>{incorrectFeedback}</div>}
                </div>
                <div className={styles.gameInfo}>
                  Time Left: {timeLeft}s
                </div>
              </>
            ) : (
              <button className={styles.startButton} onClick={startGame}>
                {timeLeft === 100 ? "Start Game" : "Play Again"}
              </button>
            )}
          </div>
          <div className={styles.symbolTable}>
            {Object.keys(symbols).map(letter => (
              <div key={letter} className={styles.symbolPair}>
                <span className={styles.letter}>{letter}</span> - <span className={styles.symbol}>{symbols[letter]}</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <PrizeModal 
          isOpen={showPrizeModal} 
          onClose={startGame} 
          prize={prize}
          isWin={isWin}
        />
      )}
    </div>
  );
};

export default CipherTextPuzzle;