import React, { useState, useEffect, useRef, useCallback } from 'react';
import PrizeModal from '../additional/PrizeModal';
import styles from './MathBalloon.module.css';
import { usePlayerState, generateRandomGold, generateRandomItem } from '../../context/PlayerStateContext';
import { mapItems } from '../../constants/itemConstants';

const MathBalloonPop = () => {
  const { playerState, setPlayerState, addItem, incrementPuzzleCompletionCount } = usePlayerState();
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(10);
  const [gameActive, setGameActive] = useState(false);
  const [currentProblem, setCurrentProblem] = useState(null);
  const [visible, setVisible] = useState(false);
  const [showPrizeModal, setShowPrizeModal] = useState(false);
  const [prize, setPrize] = useState({ gold: 0, item: null, score: 0 });
  const timerRef = useRef(null);
  const gameEndedRef = useRef(false);
  const [isWin, setIsWin] = useState(false);
  const requiredScore = 6;
  const timeAllowed = 60;

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const generateProblem = useCallback(() => {
    const operations = ['+', '-', '*'];
    const operation = operations[Math.floor(Math.random() * operations.length)];
    let num1, num2, answer;

    switch (operation) {
      case '+':
        num1 = Math.floor(Math.random() * 50) + 1;
        num2 = Math.floor(Math.random() * 50) + 1;
        answer = num1 + num2;
        break;
      case '-':
        num1 = Math.floor(Math.random() * 50) + 26; // Ensure positive result
        num2 = Math.floor(Math.random() * 25) + 1;
        answer = num1 - num2;
        break;
      case '*':
        num1 = Math.floor(Math.random() * 12) + 1;
        num2 = Math.floor(Math.random() * 12) + 1;
        answer = num1 * num2;
        break;
    }

    const wrongAnswers = [
      answer + Math.floor(Math.random() * 5) + 1,
      answer - Math.floor(Math.random() * 5) - 1,
    ];
    const options = [answer, ...wrongAnswers].sort(() => Math.random() - 0.5);

    return { problem: `${num1} ${operation} ${num2}`, answer, options };
  }, []);

  const earnReward = useCallback(() => {
    const gold = generateRandomGold();
    const mapId = 'noble_lands';
    const mapData = mapItems.find(map => map.id === mapId);
    const item = generateRandomItem(mapData.items);
    return { gold, item };
  }, []);

  const endGame = useCallback(() => {
    if (gameEndedRef.current) return;
    gameEndedRef.current = true;
    setVisible(false);
    clearInterval(timerRef.current);
    setGameActive(false);
    
    const earnedPrize = earnReward();
    setPrize({
      gold: score >= requiredScore ? earnedPrize.gold + 3 : 0,
      item: score >= requiredScore ? earnedPrize.item : null,
      score: score,
      goldImagePath: "/gold.png",
      experience: score >= requiredScore ? ((score - requiredScore + 1) * 0.01) : 0
    });

    setShowPrizeModal(true);
    setIsWin(score >= requiredScore);

    if (score >= requiredScore) {
      setPlayerState(prevState => ({
        ...prevState,
        gold: prevState.gold + earnedPrize.gold + 3,
        level: prevState.level + ((score - requiredScore + 1) * 0.01),
      }));

      incrementPuzzleCompletionCount('noble_lands', 0);
      addItem(earnedPrize.item);
    }
  }, [score, requiredScore, earnReward, setPlayerState, addItem, incrementPuzzleCompletionCount]);

  useEffect(() => {
    if (timeLeft === 0 && gameActive) {
      endGame();
    }
  }, [timeLeft, gameActive, endGame]);

  const startGame = useCallback(() => {
    gameEndedRef.current = false;
    setVisible(true);
    setIsWin(false);
    setScore(0);
    setTimeLeft(timeAllowed);
    setGameActive(true);
    setShowPrizeModal(false);
    setCurrentProblem(generateProblem());

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  }, [generateProblem, timeAllowed]);

  const handleAnswer = useCallback((selectedAnswer) => {
    if (selectedAnswer === currentProblem.answer) {
      setScore(prevScore => prevScore + 1);
    } else {
      setScore(prevScore => Math.max(0, prevScore - 1));
    }
    setCurrentProblem(generateProblem());
  }, [currentProblem, generateProblem]);

  return (
    <div>
      {!showPrizeModal ? (
        <div className={`${styles.gameContainer} ${visible ? styles.visible : ''}`}>
          <h2 className={styles.title}>Math Balloon Pop</h2>
          <p className={styles.instructions}>
            Solve as many math problems as you can in {timeAllowed} seconds!
            <br />If you score more than {requiredScore} points you will get rewarded!
          </p>
          {!gameActive && (
            <button className={styles.startButton} onClick={startGame}>
              Start Game
            </button>
          )}
          {gameActive && (
            <>
              <div className={styles.gameInfo}>
                Score: {score} | Time Left: {timeLeft}s
              </div>
              <div className={styles.gameArea}>
                <div className={styles.balloon}>
                  <div className={styles.problem}>{currentProblem.problem}</div>
                  {currentProblem.options.map((option, index) => (
                    <button 
                      key={index} 
                      className={styles.option}
                      onClick={() => handleAnswer(option)}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <PrizeModal 
          isOpen={showPrizeModal} 
          onClose={startGame} 
          prize={prize}
          isWin={isWin}
        />
      )}
    </div>
  );
};

export default MathBalloonPop;