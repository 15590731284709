import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './PuzzleHut.module.css';
import GameWrapper from './GameWrapper';
import PuzzleGraph from './additional/PuzzleGraph';
import { usePlayerState } from '../context/PlayerStateContext';

const PuzzleHut = () => {
  const navigate = useNavigate();
  const { playerState } = usePlayerState();

  const goHome = () => {
    navigate('/home');
  };

  // Transform the mapPuzzles data into the format expected by PuzzleGraph
  const puzzleData = Object.values(playerState.mapPuzzles.noble_lands.puzzles).map(puzzle => ({
    name: puzzle.title,
    count: puzzle.completedCount
  }));

  return (
    <GameWrapper backgroundImage='/PuzzleHut.webp'>
      <button onClick={goHome} className={styles.homeButton}>Home</button>
      <div className={styles.graphContainer}>
        <PuzzleGraph puzzleData={puzzleData} />
      </div>
    </GameWrapper>
  );
};

export default PuzzleHut;